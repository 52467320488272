import React from 'react';
import Slider from "react-slick";
import { FontWeight } from "../../../components/ui/FontSize";
import { Colors } from "../../../Colors";
import Text from "../../../components/ui/Text";
import Button from "../../../components/ui/Button";
import landingImage from "../images/AppPhoneDevices3_optimized.png";
import AppStoresBadges from "../../../components/ui/AppStoresBadges";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import coursesImage from "../../../assets/images/Art_Cursos2.svg";
import roadAssistanceImage from "../../../assets/images/Art_AsistenciaCarretera2.svg";
import { isMobile } from "../../../core/Utils";
import ArtRecalls from '../../../assets/images/recalls/Art_Carousel_Recalls.svg'
import { useSelector } from 'react-redux';

import marbeteDigital from "../../../assets/images/Art_MarbeteDigital.svg";

const LoginSlider = () => {

    const recallsEnabled = useSelector(state => state.features?.recalls?.enabled)

    const { t } = useTranslation()

    const history = useHistory()

    const settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        autoplaySpeed: 5000,
        arrows: false,
        adaptiveHeight: true,
    };

    const mobile = isMobile()

    const RenderRecallsSlide = () => {
        return (
            <div className="container d-flex">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6" style={{ marginTop: "30px", marginBottom: '20px' }}>
                        <img
                            src={ArtRecalls}
                            className="img-fluid"
                            alt={'Cesco digital recalls'}
                        />
                    </div>
                    <div
                        className="col-md-6 col-12 d-flex align-items-start flex-column justify-content-center p-3">
                        <h1 className="text-xxl" style={{ marginBottom: '-6px' }}>
                            <span style={{ fontWeight: FontWeight.medium }}>
                                {t("login:slider.recalls.title")}
                            </span>
                        </h1>

                        <h1 className="text-xxl">
                            <span style={yellowSubTitleStyle}>
                                {t("login:mid.course.title.three")}
                            </span>
                        </h1>

                        <Text
                            style={bodyTextStyle}
                            i18nKey="login:slider.recalls.description"
                            className="text-md"
                        />

                        <Button
                            onClick={() => history.push('/recalls')}
                            style={{ marginTop: "15px" }}
                            disabled={false}
                            className="d-lg-none btn-primary btn-block"
                        >
                            {t("login:slider.recalls.button")}
                        </Button>

                        <button
                            onClick={() => history.push('/recalls')}
                            style={{ marginTop: "15px" }}
                            disabled={false}
                            className="d-none d-lg-block btn btn-primary btn-md"
                        >
                            {t("login:slider.recalls.button")}
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    const RenderCoursesSlide = () => {
        return (
            <div className="container d-flex">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6" style={{ marginTop: "30px", marginBottom: '20px' }}>
                        <img
                            src={coursesImage}
                            className="img-fluid"
                            alt={'Cesco digital cursos en linea'}
                        />
                    </div>
                    <div
                        className="col-md-6 col-12 d-flex align-items-start flex-column justify-content-center p-3">
                        <h1 className="text-xxl" style={{ marginBottom: '-6px' }}>
                            {t("login:mid.course.title.one")}
                            <span style={{ fontWeight: FontWeight.medium }}>
                                {t("login:mid.course.title.two")}
                            </span>
                        </h1>

                        <h1 className="text-xxl">
                            <span style={yellowSubTitleStyle}>
                                {t("login:mid.course.title.three")}
                            </span>
                        </h1>

                        <Text
                            style={bodyTextStyle}
                            i18nKey="login:mid.course.description"
                            className="text-md"
                        />

                        <Button
                            onClick={() => history.push('/courses')}
                            style={{ marginTop: "15px" }}
                            disabled={false}
                            className="d-lg-none btn-primary btn-block"
                        >
                            {t("login:mid.course.button")}
                        </Button>

                        <button
                            onClick={() => history.push('/courses')}
                            style={{ marginTop: "15px" }}
                            disabled={false}
                            className="d-none d-lg-block btn btn-primary btn-md"
                        >
                            {t("login:mid.course.button")}
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    const RenderRoadServiceSlide = () => {
        return (
            <div className="container d-flex">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6" style={{ marginTop: "30px", marginBottom: '20px' }}>
                        <img
                            src={roadAssistanceImage}
                            className="img-fluid"
                            alt={'Cesco digital asistencia en la carretera'}
                        />
                    </div>
                    <div
                        className="col-md-6 col-12 d-flex align-items-start flex-column justify-content-center p-3">
                        <h1 className="text-xxl" style={{ lineHeight: 1 }}>
                            {t("login:slider.roadService.title")}
                            <span style={{ fontWeight: FontWeight.medium }}>
                                {t("login:slider.roadService.subTitle.0")}
                            </span>
                        </h1>

                        <h1 className="text-xl">
                            <span style={yellowSubTitleStyle}>
                                {t("login:slider.roadService.subTitle.1")}
                            </span>
                        </h1>

                        <Text
                            style={bodyTextStyle}
                            i18nKey="login:slider.roadService.description"
                            className="text-md"
                        />

                        <Button
                            onClick={() => history.push('/more#roadService')}
                            style={{ marginTop: "15px" }}
                            disabled={false}
                            className="d-lg-none btn-primary btn-block"
                        >
                            {t("login:slider.roadService.button")}
                        </Button>

                        <button
                            onClick={() => history.push('/more#roadService')}
                            style={{ marginTop: "15px" }}
                            disabled={false}
                            className="d-none d-lg-block btn btn-primary btn-md"
                        >
                            {t("login:slider.roadService.button")}
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    const RenderCescoAppBanner = () => {
        return (
            <div className="container d-flex">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6" style={{ marginTop: "30px", marginBottom: '20px' }}>
                        <img
                            src={landingImage}
                            className="img-fluid"
                            alt={'Cesco digital aplicacion'}
                        />
                    </div>
                    <div
                        className="col-md-6 col-12 d-flex align-items-start flex-column justify-content-center p-3">
                        <h1
                            aria-label={`CESCO Digital App ${t(
                                "login:mid.titleCaption1"
                            )}`}
                            style={{
                                lineHeight: 1,
                                color: "black",
                                fontWeight: 400
                            }}
                            className="text-xxl"
                        >
                            <span aria-hidden={true} className="text-xxl">CESCO </span>
                            <span
                                className="text-xxl"
                                aria-hidden={true}
                                style={{
                                    fontWeight: "bold",
                                    color: "black",
                                }}
                            >
                                Digital App
                            </span>
                            <br />
                            <span
                                style={yellowSubTitleStyle}
                                aria-hidden={true}
                                className="text-xl"
                            >
                                {t("login:mid.titleCaption1")}
                            </span>
                        </h1>

                        <Text
                            style={bodyTextStyle}
                            i18nKey="login:mid.caption"
                            className="text-md"
                        />

                        <div
                            style={{
                                width: '100%',
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <AppStoresBadges />
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const RenderMarbeteDigitalBanner = () => {
        return (
            <div className="container d-flex">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6" style={{ marginTop: "30px", marginBottom: '20px' }}>
                        <img
                            src={marbeteDigital}
                            className="img-fluid"
                            alt={'Cesco digital marbete digital'}
                        />
                    </div>
                    <div
                        className="col-md-6 col-12 d-flex align-items-start flex-column justify-content-center p-3">
                        <h1 className="text-xxl">
                            <span style={{ fontWeight: FontWeight.medium }}>
                                {t("login:slider.marbeteDigital.title.0")}
                            </span>
                            {t("login:slider.marbeteDigital.title.1")}
                        </h1>

                        <Text
                            style={bodyTextStyle}
                            i18nKey="login:slider.marbeteDigital.caption"
                            className="text-md mb-3"
                        />

                        <Button
                            onClick={() => history.push('/courses')}
                            style={{ marginTop: "15px" }}
                            disabled={false}
                            className="d-lg-none btn-primary btn-block"
                        >
                            {t("login:slider.marbeteDigital.button")}
                        </Button>

                        <button
                            onClick={() => history.push('/courses')}
                            style={{ marginTop: "15px" }}
                            disabled={false}
                            className="d-none d-lg-block btn btn-primary btn-md"
                        >
                            {t("login:slider.marbeteDigital.button")}
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div>

            <Slider {...settings} style={{ height: mobile ? 650 : 500, marginBottom: '3rem', paddingBottom: '1rem', backgroundColor: 'white' }}>
                {/*<div>
                        <a
                            href={'https://www.autoexpreso.com/home'}
                            target="_blank"
                        >
                            <img
                                src={landingCescoBanner}
                                style={{ width: '100%' }}
                                alt={t("login:accessibility.banner")}
                            />
                        </a>
                    </div>*/}

                {
                    // Recalls
                    recallsEnabled && (
                        <RenderRecallsSlide />
                    )
                }

                {
                    // Courses
                    <RenderCoursesSlide />
                }


                {
                    // Road Service
                    <RenderRoadServiceSlide />
                }

                {
                    // Cesco app banner
                    <RenderCescoAppBanner />
                }

                {
                    // Marbete digital
                    <RenderMarbeteDigitalBanner />
                }
            </Slider>
        </div>
    );
};

const buttonStyle = {
    padding: 30
}

const yellowSubTitleStyle = {
    color: Colors.yellow,
    fontWeight: FontWeight.medium
}

const bodyTextStyle = {
    color: "gray",
    lineHeight: 1.4,
}

export default LoginSlider;