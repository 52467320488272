import React from "react";
import cescoDigitalLogo from "../assets/images/LogoCesco.svg";
import pritsLogo from "../assets/images/Footer_LogoPRITS.svg";
import dtopLogo from "../assets/images/Footer_LogoDTOP.svg";
import gobLogo from "../assets/images/Footer_LogoSelloGob.svg";
import SvgFooterImg from "./SvgFooterImg";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Text from "../components/ui/Text";
import { version } from "../core/APICaller";
import ReactTooltip from "react-tooltip";
import generatedGitInfo from "../generatedGitInfo.json";

const Footer = () => {
    const user = useSelector((state) => state?.login?.user);
    let year = new Date().getFullYear();
    const { t } = useTranslation();
    return (
        <footer
            id="SVGfooterTopShape"
            className="position-relative cesco-footer-gradient"
        >
            <div
                className={`container ${
                    user ? "space-top-3" : "space-top-4"
                } space-bottom-2`}
            >
                <div className="container d-flex justify-content-lg-around justify-content-between align-items-center p-0">
                    {_.isEmpty(user) && (
                        <React.Fragment>
                            <img
                                id={"img-cd"}
                                alt={t(
                                    "layout:footer.accessibility.alts.logoCD"
                                )}
                                src={cescoDigitalLogo}
                            />

                            <img
                                id={"img-prits"}
                                alt={t(
                                    "layout:footer.accessibility.alts.logoPrits"
                                )}
                                src={pritsLogo}
                            />
                            <img
                                id={"img-dtop"}
                                alt={t(
                                    "layout:footer.accessibility.alts.logoDtop"
                                )}
                                src={dtopLogo}
                            />
                            <img
                                id={"img-escudo-pr"}
                                alt={t(
                                    "layout:footer.accessibility.alts.logoPr"
                                )}
                                src={gobLogo}
                            />
                        </React.Fragment>
                    )}
                </div>

                {_.isEmpty(user) && <hr className="opacity-md my-7" />}
                <div
                    className="row align-items-lg-center"
                    style={{ zIndex: 3000 }}
                >
                    <div
                        className="col-12 mb-4 mb-md-0"
                        style={{
                            fontSize: "15px",
                            textAlign: "center",
                            lineHeight: 1.6,
                        }}
                    >
                        <Text style={{ color: "white" }}>
                            © CESCO Digital. {year} DTOP
                        </Text>
                        <br className="d-lg-none" />

                        {/*<a style={underline} href="#">FAQ</a>*/}
                        <a
                            rel="noreferrer"
                            style={{ textDecoration: "underline" }}
                            target="_blank"
                            href="https://static.cescodigital.pr.gov/agreements/TermsAndConditions.pdf"
                            className="termsAndConditions"
                        >
                            {t("layout:footer.termsAndConditions")}
                        </a>
                        <Link to="/locations" style={underline}>
                            {t("layout:footer.locations")}
                        </Link>
                        <ReactTooltip
                            id="versionTip"
                            place="top"
                            type="dark"
                            effect="float"
                        />
                        <span
                            data-tip={`Version:${version} BuildDate:${generatedGitInfo.buildDate} ${generatedGitInfo.gitCommitId}`}
                            data-for="versionTip"
                            style={{
                                color: "white",
                                marginLeft: "10px",
                                opacity: 0.5,
                            }}
                        >
                            {version}
                        </span>
                        <br />
                        <span style={{ color: "white" }} className="text-xxs">
                            {t("layout:footer.authorized")}
                        </span>
                    </div>
                </div>
            </div>
            <figure
                className="position-absolute top-0 right-0 left-0"
                aria-hidden={true}
            >
                <SvgFooterImg />
            </figure>
        </footer>
    );
};

const underline = {
    textDecoration: "underline",
    marginLeft: "10px",
};

export default Footer;
