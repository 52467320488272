import React from "react";
import './mdl.css'
import { useTranslation } from "react-i18next";

import iconHeader from '../../assets/images/mdl/Art_Instrucciones_mDL-ER_Liveness.svg'
import iconLicense from '../../assets/images/mdl/icon_mD-ER_Instrucciones_Licencia.svg'
import iconIlumination from '../../assets/images/mdl/icon_mD-ER_Instrucciones_Iluminacion.svg'
import iconApp from '../../assets/images/mdl/icon_mD-ER_Instrucciones_CescoApp.svg'
import { useHistory, useLocation } from 'react-router-dom'

const MdlOnline = () => {

    const { t } = useTranslation()
    const history = useHistory()

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const id = params.get("id")


    const buttonAction = () => {
        history.push(`/dl?path=mdl-extended-review&id=${id}`)
    }

    return (
        <div>
            <div className="mdl-container">
                <div className="header">
                    <h1>{t("mdl:online.title")}</h1>
                    <img src={iconHeader} className="iconHeader" alt="" aria-hidden="true" />
                </div>

                <p>
                    {t("mdl:online.text.0")}
                </p>

                <p>
                    {t("mdl:online.text.1")}
                    <strong>{t("mdl:online.text.2")}</strong>
                    {t("mdl:online.text.3")}
                    <strong>{t("mdl:online.text.4")}</strong>
                    {t("mdl:online.text.5")}
                </p>

                <p>
                    <strong>{t("mdl:online.continue")}:</strong>
                </p>

                <div>
                    <div className="listItemHead">
                        <img src={iconLicense} className="icon" alt="" aria-hidden="true" />
                        <h3>
                            <strong>{t("mdl:online.license.title")}</strong>
                        </h3>
                    </div>
                    <div>
                        <p>
                            {t("mdl:online.license.text")}
                        </p>
                    </div>
                </div>

                <div >
                    <div className="listItemHead">
                        <img src={iconIlumination} className="icon" alt="" aria-hidden="true" />
                        <h3 >
                            <strong>{t("mdl:online.ilumination.title")}</strong>
                        </h3>
                    </div>
                    <div>
                        <p>
                            {t("mdl:online.ilumination.text")}
                        </p>
                    </div>
                </div>

                <div style={{ marginBottom: 40 }}>
                    <div className="listItemHead">
                        <img src={iconApp} className="icon" alt="" aria-hidden="true" />
                        <h3>
                            <strong>{t("mdl:online.application.title")}</strong>
                        </h3>
                    </div>
                    <div>
                        <p>
                            {t("mdl:online.application.text")}
                        </p>
                    </div>
                </div>

                <button 
                onClick={() => buttonAction()}
                className="btn btn-primary w-100">
                    {t("mdl:online.button")}
                </button>
            </div>
        </div>
    )
};

export default MdlOnline;
