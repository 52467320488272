import React from "react";

import { Colors } from "../../Colors";

const TextPill = (props) => (
    <div
        className={`px-2 py-1`}
        style={{ 
            borderRadius: 100, 
            color: 'white',
            backgroundColor: props?.color || Colors.gray,
            display: 'inline-block',
            maxWidth: '100%',
            minWidth: '100px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
        }}
    >
        <small>
            <strong>{props.pillContent || "000"}</strong>
        </small>
    </div>
);

export default TextPill;