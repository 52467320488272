import React from "react";
import errorIcon from '../../../assets/images/recalls/error_icon.png'
import { Colors } from "../../../Colors";
import PropTypes from 'prop-types';

const ErrorMessage = (props) => {

    const { text, rounded } = props

    return (
        <div className={`${rounded ? 'rounded' : ''} p-3 d-flex align-items-center`} style={{ background: Colors.error, color: 'white' }}>
            <div className="d-flex align-items-center ml-2 mr-4">
                <img src={errorIcon} alt="" style={{ height: 24, width: 24 }} />
            </div>
            <span className="text-sm text-medium">{text || 'Error'}</span>
        </div>
    )
}

ErrorMessage.propTypes = {
    text: PropTypes.string,
    rounded: PropTypes.bool
};

export default ErrorMessage