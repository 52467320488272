import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { openVehicleLicenseReport } from "../../redux/loading/loadingState.combine";
import dropDownIcon from "../../assets/images/icon_download.svg";
import { Colors } from "../../Colors";
import { useTranslation } from "react-i18next";
import Text from "../ui/Text";

import carroIcon from "../../assets/images/carro-icon.svg";
import gravamenIcon from "../../assets/images/icon-gravamen.svg";
import isCleanIcon from "../../assets/images/icon_Badge_Vehiculo.svg";
import tagExpired from "../../assets/images/icon_Vehiculo_MarbeteExpirado.svg";

import motoraIcon from "../../assets/images/motora-icon.svg";
import carretonIcon from "../../assets/images/carreton-icon.svg";
import virtualTime from "../../assets/images/icon-tiempo-virtual.svg";
import marbeteAlert from "../../assets/images/icon-alerta-marbete.svg";
import tagPreboarded from "../../assets/images/electronic-tag/pre-registered-vehicle.svg";
import tagPreboardedPending from "../../assets/images/electronic-tag/pre-registered-pending.svg";
import tagPreboardedExpired from "../../assets/images/electronic-tag/MarbeteElectronico_Expirado.svg";

import tagPreboardedBike from "../../assets/images/icon_Vehiculo_Moto_MarbeteElectronico.svg";
import tagPreboardedPendingBike from "../../assets/images/icon_Vehiculo_Moto_MarbeteElectronico_Pending.svg";

import ReactTooltip from "react-tooltip";
import PropTypes from 'prop-types'

import electronicTagVehicle from '../../assets/images/electronic-tag/icon_Vehiculo_Carro_MarbeteDigital.svg'
import electronicTagBike from '../../assets/images/electronic-tag/icon_Vehiculo_Motora_MarbeteDigital.svg'

import moment from "moment";


const VehicleListItem = (props) => {

    const preBoardingEnabled = useSelector(s => s?.features?.preBoarding?.enabled)

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const { vehicle, autoexpreso } = props

    const flagStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
    };

    const flagItemStyle = {
        marginLeft: "15px",
        height: "26px",
    };

    const vehicleIcon = (type) => {

        const preboardingStatus = vehicle?.tagPreboarding?.status
        const tagType = vehicle?.tag?.type
        const isBike = type === "MOTOCICLETA" 

        if (tagType === "DIGITAL") {

            if (isBike) {
                return { icon: electronicTagBike }
            }

            return { icon: electronicTagVehicle }
        }

        if (preboardingStatus === "registered" && preBoardingEnabled) {

            if (isBike) {
                return { icon: tagPreboardedBike }
            }

            return { icon: tagPreboarded }
        }

        if (preboardingStatus === "pending" && preBoardingEnabled) {

            if (isBike) {
                return { icon: tagPreboardedPendingBike }
            }

            return { icon: tagPreboardedPending }
        }

        switch (type) {
            case "MOTOCICLETA":
                return { icon: motoraIcon };
            case "CARRETON":
            case "ARRASTRE":
                return { icon: carretonIcon };
            default:
                return { icon: carroIcon };
        }
    };

    const RenderRightTagIcon = () => {

        const tagType = vehicle?.tag?.type
        const tagExpiration = vehicle?.tag?.expirationDate

        let digitalExpiredText = t("vehicleList:digitalTagExpired")
        let expiredText = t("vehicleList:tagExpired")

        const today = moment();
        const expirationDate = moment(tagExpiration);
        const isTagExpired = today.isAfter(expirationDate);

        if (!isTagExpired) {
            digitalExpiredText = t("vehicleList:digitalTagToBeExpired")
            expiredText = t("vehicleList:tagToBeExpired")
        }

        if (props.canRenewTag) {
            if (tagType === "DIGITAL") {
                return (
                    <img
                        role={"button"}
                        aria-label={digitalExpiredText}
                        alt={""}
                        style={{ height: "26px" }}
                        src={tagPreboardedExpired}
                        data-tip={digitalExpiredText}
                        data-for="vehicle-tooltip"
                    />
                )
            } else {
                return (
                    <img
                        role={"button"}
                        aria-label={t("vehicleList:tagExpired", {
                            brand: props.brand,
                            model: props.model,
                            year: props.year,
                        })}
                        alt={""}
                        style={{ height: "26px" }}
                        src={tagExpired}
                        data-tip={expiredText}
                        data-for="vehicle-tooltip"
                    />
                )
            }
        }
    }

    const openRenewNotification = (e) => {
        if (e) e.stopPropagation();
        dispatch(openVehicleLicenseReport(props.id));
    };

    const changeRoute = (id) => {
        if (autoexpreso) {
            return history.push(`/autoexpreso/${id}`);
        }

        history.push(`/vehicle-details/${id}`);
    };

    let { icon } = vehicleIcon(props.type);
    return (
        <button
            onClick={() => changeRoute(props.id)}
            style={buttonStyle}
            className="hoverable"
        >
            <ReactTooltip
                place="left"
                type="dark"
                effect="float"
                id="vehicle-tooltip"
            />

            <div style={{ display: "flex", flexDirection: "row" }}>
                <img alt={""} aria-hidden={true} src={icon} width={40} />
                <div style={{ marginLeft: "1rem", textAlign: "left" }}>
                    <Text style={{ fontWeight: "bold", color: 'black' }} className="text">
                        {props.year} {props.brand}
                    </Text>
                    <br />
                    <Text style={{ color: "#748187" }} className="text">
                        {props.model}
                    </Text>
                    <br />
                    <Text style={{ color: "#748187" }} className="text">
                        {props.plate}
                    </Text>
                </div>
            </div>
            <div style={flagStyle}>
                {/* {
                    // TODO: ADD CONDITIONAL
                    <img
                        src={marbeteAlert}
                        style={flagItemStyle}
                        alt={t("vehicleList:marbeteAlert")}
                        data-tip={t("vehicleList:marbeteAlert")}
                        data-for="vehicle-tooltip"
                    />
                } */}

                {/* {
                    // TODO: ADD CONDITIONAL
                    <img
                        src={virtualTime}
                        style={flagItemStyle}
                        alt={t("vehicleList:virtualTime")}
                        data-tip={t("vehicleList:virtualTime")}
                        data-for="vehicle-tooltip"
                    />
                } */}

                {props.gravamen && (
                    <img
                        src={gravamenIcon}
                        style={flagItemStyle}
                        alt={t("vehicleList:hasGravamen")}
                        data-tip={t("vehicleList:hasGravamen")}
                        data-for="vehicle-tooltip"
                    />
                )}

                {
                    RenderRightTagIcon()
                }

                {props.fines > 0 && (
                    <span
                        aria-label={t("vehicleList:hasFines", {
                            fines: props.fines,
                        })}
                        style={spanFines}
                        className="badge badge-sm badge-danger rounded-circle"
                        data-tip={t("vehicleList:fines")}
                        data-for="vehicle-tooltip"
                    >
                        <span aria-hidden={true}>
                            <Text
                                style={{ marginTop: "2px", fontSize: "13px" }}
                            >
                                {props.fines}
                            </Text>
                        </span>
                    </span>
                )}

                {props.isClean && (
                    <img
                        src={isCleanIcon}
                        style={flagItemStyle}
                        alt={t("vehicleList:noFines")}
                        data-tip={t("vehicleList:noFines")}
                        data-for="vehicle-tooltip"
                    />
                )}
            </div>
        </button>
    );
};

VehicleListItem.propTypes = {
    key: PropTypes.string,
    id: PropTypes.string,
    year: PropTypes.number,
    brand: PropTypes.string,
    model: PropTypes.string,
    plate: PropTypes.string,
    type: PropTypes.string,
    gravamen: PropTypes.string,
    isClean: PropTypes.bool,
    fines: PropTypes.number,
    canRenewTag: PropTypes.bool,
    history: PropTypes.object,
    vehicle: PropTypes.object,
}

const buttonStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 36px 15px 20px",
    border: "none",
    borderBottom: `1px solid ${Colors.borderGray}`,
    width: "100%",
};

const spanFines = {
    marginLeft: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "24px",
};

export default VehicleListItem;
