import React, { useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Colors } from "../../../Colors"
import xIcon from '../../../assets/images/x-mark.svg'
import Button from "../../../components/ui/Button"
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask";
import subscribeArt from '../../../assets/images/recalls/subscribe_art.png'

const SubscriptionSuccessModal = (props) => {

    const { title, children, toggle, action, actionText, noPadding } = props

    const [error, setError] = useState(false)

    const { t } = useTranslation()

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <div
                style={{ backgroundColor: Colors.bgGray, borderTopRightRadius: 'calc(.625rem - 1px)', borderTopLeftRadius: 'calc(.625rem - 1px)' }}
                className="w-100 d-flex justify-content-between p-3 border-bottom">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <strong>{t('recalls:subscribe.success.modalTitle')}</strong>
                </div>

                <button
                    onClick={() => toggle(false)}
                    className="d-flex align-items-center hoverable"
                    style={{ border: 0, backgroundColor: 'transparent' }}
                >
                    <img src={xIcon} alt="" aria-hidden style={{ height: 20, width: 20 }} />
                </button>
            </div>

            <ModalBody style={{ padding: 0 }}>
                <div className="p-3 text-center">
                    <div className="d-flex justify-content-center mb-2">
                        <img src={subscribeArt} alt="" aria-hidden style={{ width: 300 }} />
                    </div>
                    <div className="mb-2">
                        <strong className="text-uppercase">{t('recalls:subscribe.success.title')}</strong>
                    </div>
                    <div className="mb-2">
                        <span>{t('recalls:subscribe.success.text')}</span>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <Button
                    className="btn-primary w-40"
                    onClick={() => toggle(false)}
                    disabled={false}
                >
                    {t('recalls:subscribe.success.button')}
                </Button>
            </ModalFooter>
        </Modal>

    )
}

export default SubscriptionSuccessModal