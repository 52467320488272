import React, { useState } from "react"
import Card from "../../../components/ui/Card"
import TextPill from "../../../components/ui/TextPill"
import DateSquare from "../../../components/ui/DateSquare"
import { Collapse } from "reactstrap"
import { Colors } from "../../../Colors"

import noRecallsArt from '../../../assets/images/recalls/Art_Recalls_NoHayRetiros.png'
import TextWithSmLabel from "./TextWithSmLabel"
import { useTranslation } from "react-i18next"
import { RenderRecallStatus, RenderRecallStatusColor} from "./RenderRecallStatus"
import CaretDropdown from "../../../components/ui/CaretDropdown"

const RecallsList = ({ recalls }) => {

    const { t } = useTranslation()

    if (!recalls || recalls?.length === 0) return (
        <div className="col-12">
            <Card>
                <div className="d-flex justify-content-center flex-column align-items-center">
                    <div>
                        <img
                            style={{ width: 285 }}
                            src={noRecallsArt}
                            aria-hidden
                            alt={""}
                        />
                    </div>

                    <div className="mb-3">
                        <strong>{t("recalls:openRecalls.emptyState")}</strong>
                    </div>
                </div>
            </Card>
        </div>
    )

    return (
        <div className="col-12">
            <Card noPadding>
                {
                    recalls && recalls?.length > 0 &&
                    <div className="py-3 d-none d-lg-block" style={{ borderBottom: '1px solid #DBE3E6', fontSize: 16 }}>
                        <div className="row">
                            <div className="col-9">
                                <div className="d-flex align-items-center">
                                    <span className="text-bold px-3">{t("recalls:openRecalls.date")} / {t("recalls:openRecalls.description")}</span>
                                </div>
                            </div>

                            <div className="col-3 d-flex align-items-center justify-content-center">
                                <span className="text-bold">{t("recalls:openRecalls.status")}</span>
                            </div>
                        </div>
                    </div>
                }


                {
                    recalls && recalls?.length > 0 &&
                    recalls.map((recall, index) => {
                        return (
                            <RecallListItem key={index} data={recall} />
                        )
                    })
                }
            </Card>
        </div>
    )
}

const RecallListItem = ({ key, data }) => {

    const { t } = useTranslation()

    const [dropDown, handleDropDown] = useState(false);

    return (
        <div
            className="container"
            key={key}
            aria-expanded={dropDown}
            aria-controls="collapse"
            style={{
                border: 0,
                backgroundColor: dropDown ? '#EDF0F3' : 'white',
            }}
            onClick={() => handleDropDown(!dropDown)}
        >

            <div className="row" style={{ borderBottom: '1px solid #DBE3E6' }} >
                <div className="col py-3">
                    <div className="d-flex align-items-center justify-content-between mb-lg-0">
                        <div className="d-flex align-items-start align-items-lg-center justify-content-start">
                            <div style={{ minWidth: 45 }}>
                                <DateSquare date={data?.date} style={{ backgroundColor: 'white'}} />
                            </div>
                            <div className="ml-3 ">
                                <div className="mb-2 mb-lg-0 mr-3">
                                    <span style={{ fontSize: 16 }}>{data?.campaign}</span>
                                </div>
                                <div className="d-lg-none">
                                    <TextPill
                                        pillContent={RenderRecallStatus(data?.status)}
                                        color={RenderRecallStatusColor(data?.status)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-start align-items-lg-center">
                            <div className="d-none d-lg-flex">
                                <TextPill
                                    pillContent={RenderRecallStatus(data?.status)}
                                    color={RenderRecallStatusColor(data?.status)}
                                />
                            </div>
                            <div>
                                <CaretDropdown dropDown={dropDown} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Collapse isOpen={dropDown}>
                <div className="row pt-3">
                    <div className="col-5 col-lg-2 text-left">
                        <TextWithSmLabel label={t("recalls:openRecalls.nhtsaNumber")} text={data?.nhtsaNumber} />
                    </div>
                    <div className="col-7 col-lg-10 text-left">
                        <TextWithSmLabel label={t("recalls:openRecalls.retireNumber")} text={data?.campaignNumber} />
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col text-left">
                        <TextWithSmLabel label={t("recalls:openRecalls.description")} text={data?.description} />
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col text-left">
                        <TextWithSmLabel label={t("recalls:openRecalls.safetyRisk")} text={data?.safetyRisk} />
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col text-left">
                        <TextWithSmLabel label={t("recalls:openRecalls.remedy")} text={data?.remedy} />
                    </div>
                </div>
            </Collapse>
        </div>
    )
}

export default RecallsList
