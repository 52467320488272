import React from "react"
import { useTranslation } from "react-i18next"
import ComponentTitle from "../../../components/ui/ComponentTitle"
import Card from "../../../components/ui/Card"
import Text from "../../../components/ui/Text"

import RecallsList from "./RecallsList"
import { Colors } from "../../../Colors"
import { FontWeight } from "../../../components/ui/FontSize"
import TextWithSmLabel from "./TextWithSmLabel"
import RecallsSearchError from "./RecallsSearchError"
import LoadingSpinner from "../../../components/ui/LoadingSpinner"

const RecallsContent = ({ result, searchError, loading }) => {

    const { t } = useTranslation()

    const vehicle = result?.vehicle
    const recalls = result?.recalls

    if (loading) {
        return <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center my-5">
                        <LoadingSpinner />
                    </div>
                </div>
            </div>
        </div>
    }

    if (searchError) {
        return (
            <RecallsSearchError searchError={searchError} />
        )
    }

    if (!vehicle) return null

    return (
        <div className="container">

            <div className="row mb-5">
                <div className="col-12">
                    <ComponentTitle i18nKey={"recalls:vehicle.title"} noBorder bold />
                    <Card>
                        <div className="row">
                            <div className="border-bottom pb-3">
                                <small style={{ color: Colors.gray, fontSize: 12, textTransform: "uppercase" }}>{t("recalls:vehicle.title")}</small>
                                <br />
                                <strong className="vehicleText">{vehicle?.year} {vehicle?.make} {vehicle?.model}</strong>
                            </div>
                            <div className="pt-3">
                                <TextWithSmLabel label={t("recalls:vehicle.moreInfo")} text={t("recalls:vehicle.moreInfoText")} textBold />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>

            <div className="row mb-3 px-3 px-lg-0">
                <div className="col-12 col-lg-6  mb-2 mb-lg-0">
                    <Text
                        className={"text-md"}
                        style={{
                            fontWeight: FontWeight.bold,
                            letterSpacing: "0.03rem",
                            color: Colors.darkGray,
                        }}
                        i18nKey={"recalls:openRecalls.title"}
                    />
                </div>

                <div className="col-12 col-lg-6 d-flex justify-content-start justify-content-lg-end align-items-center">
                    <Text
                        className={"text-md mr-1"}
                        style={{
                            fontWeight: FontWeight.bold,
                            letterSpacing: "0.03rem",
                            color: Colors.darkGray,
                        }}
                        i18nKey={"recalls:openRecalls.total"}
                    />
                    <small style={{ backgroundColor: Colors.primary, borderRadius: "50px", color: 'white' }} className="px-2 py-1 text-bold">{recalls?.length}</small>
                </div>
            </div>

            <div className="row">
                <RecallsList recalls={recalls} />
            </div>
        </div>
    )
}

export default RecallsContent