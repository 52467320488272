import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setRecaptcha } from "../../../redux/app/app.combine"
import { Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Colors } from "../../../Colors"
import xIcon from '../../../assets/images/x-mark.svg'
import Button from "../../../components/ui/Button"
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask";

import vinIcon from '../../../assets/images/recalls/vin_number_icon.png'
import registryNumberIcon from '../../../assets/images/recalls/numbers.png'
import registryNumberIconWhite from '../../../assets/images/recalls/numbers_white.png'
import emailIcon from '../../../assets/images/recalls/mail_icon.png'
import infoIcon from '../../../assets/images/recalls/Information_icon.png'
import errorIcon from '../../../assets/images/recalls/error_icon.png'

import artVinLicensePlate from '../../../assets/images/recalls/Art_Recalls_NumeroRegistro.svg'
import ErrorMessage from "./ErrorMessage"
import store from "../../../store"
import TextInput from "../../../components/ui/TextInput"
import LoadingSpinner from "../../../components/ui/LoadingSpinner"

const SubscribeModal = (props) => {

    const RECALLS_API_ENDPOINT = process.env.REACT_APP_RECALLS_API

    const { title, toggle, action, vehicleInfo, recaptchaRef } = props

    const [error, setError] = useState(false)

    const [vin, setVin] = useState('')
    const [registryNumber, setRegistryNumber] = useState('')
    const [email, setEmail] = useState('')
    const [confirmationEmail, setConfirmationEmail] = useState('')

    const [registryError, setRegistryError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [confirmationEmailError, setConfirmationEmailError] = useState(false)

    const [moreInfo, setMoreInfo] = useState(false)

    const [loading, setLoading] = useState(false)
    const [shouldSubscribe, setShouldSubscribe] = useState(false)

    const { t } = useTranslation()

    const recaptchaToken = useSelector(state => state.recaptcha.token)
    const dispatch = useDispatch()

    const handleExecuteRecaptcha = async () => {
        dispatch(setRecaptcha(null))
        const { current } = recaptchaRef;
        await current.execute();
    }

    const initiateSubscribe = async () => {
        setLoading(true)
        setError(false)
        await handleExecuteRecaptcha()
        setShouldSubscribe(true)
    }

    useEffect(() => {
        const performSubscribe = async () => {
            if (!recaptchaToken) return
            if (!shouldSubscribe) return

            const body = {
                vin: vehicleInfo?.vin,
                registrationNumber: registryNumber,
                email: email
            }

            try {
                const res = await fetch(`${RECALLS_API_ENDPOINT}/recalls/subscribe`, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: { 'recaptcha-token': recaptchaToken }
                })

                if (res.status === 201) {
                    action()
                } else if (res.status === 404) {
                    setError(t("recalls:subscribe.error.404"))
                } else if (res.status === 409) {
                    setError(t("recalls:subscribe.error.409"))
                } else {
                    setError(t("recalls:subscribe.error.500"))
                }
            } catch (err) {
                setError(t("recalls:subscribe.error.500"))
            } finally {
                setLoading(false)
                setShouldSubscribe(false)
            }
        }

        performSubscribe()
    }, [recaptchaToken, shouldSubscribe])

    const toggleError = (errorToggler, bool) => errorToggler(bool)

    const handleButtonDisabled = () => {
        if (loading) return true
        if (email === "") return true
        if (emailError || confirmationEmailError) return true
        if (registryNumber === "") return true
        if (registryError) return true
        if (email !== confirmationEmail) {
            if (confirmationEmail !== "") {
                // setError(t("recalls:subscribe.error.noMatch"))
                setConfirmationEmailError(true)
            }
            return true
        }

        return false
    }

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <div
                style={{ backgroundColor: Colors.bgGray, borderTopRightRadius: 'calc(.625rem - 1px)', borderTopLeftRadius: 'calc(.625rem - 1px)' }}
                className="w-100 d-flex justify-content-between p-3 border-bottom">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <strong>{title || "No title"}</strong>
                </div>

                <button
                    onClick={() => toggle(false)}
                    className="d-flex align-items-center hoverable"
                    style={{ border: 0, backgroundColor: 'transparent' }}
                >
                    <img src={xIcon} alt="" aria-hidden style={{ height: 20, width: 20 }} />
                </button>
            </div>
            <ModalBody style={{ padding: 0 }}>
                {
                    loading ?
                        <div className="d-flex justify-content-center align-items-center py-10">
                            <LoadingSpinner size={200} />
                        </div>
                        :
                        <div>
                            <div className="p-3 lh-md">
                                <span>{t("recalls:subscribe.instructions")}</span>
                            </div>

                            {
                                error &&
                                <div className="mb-2">
                                    <ErrorMessage text={error} />
                                </div>
                            }

                            <div className="mb-4">
                                <InputSection
                                    icon={vinIcon}
                                    value={vehicleInfo?.vin}
                                    setValue={setVin}
                                    placeholder={t("recalls:subscribe.inputs.vin")}
                                    disabled={true}
                                    code="string"
                                    error={false}
                                />
                                <InputSection
                                    icon={registryError ? registryNumberIconWhite : registryNumberIcon}
                                    value={registryNumber}
                                    setValue={setRegistryNumber}
                                    placeholder={t("recalls:subscribe.inputs.registrationNumber")}
                                    infoAction={() => setMoreInfo(!moreInfo)}
                                    code="registrationNumber"
                                    toggleError={toggleError}
                                    errorToggler={setRegistryError}
                                    type="number"
                                />
                                <InputSection
                                    icon={emailIcon}
                                    value={email}
                                    setValue={setEmail}
                                    error={emailError}
                                    placeholder={t("recalls:subscribe.inputs.email")}
                                    code="email"
                                    toggleError={toggleError}
                                    errorToggler={setEmailError}
                                />
                                <InputSection
                                    icon={emailIcon}
                                    value={confirmationEmail}
                                    setValue={setConfirmationEmail}
                                    error={confirmationEmailError}
                                    placeholder={t("recalls:subscribe.inputs.emailConfirm")}
                                    code="email"
                                    toggleError={toggleError}
                                    errorToggler={setConfirmationEmailError}
                                />
                            </div>
                        </div>
                }

                <div className="d-flex px-3 mb-2">
                    <Button
                        className="btn btn-cesco btn-block"
                        onClick={initiateSubscribe}
                        disabled={handleButtonDisabled()}
                    >
                        <span>{t("recalls:subscribe.button")}</span>
                    </Button>
                </div>


            </ModalBody>
            <ModalFooter className="d-flex justify-content-center p-0 pb-3" style={{ border: 0 }}>
                {
                    moreInfo &&
                    <div className="p-3 mt-2" style={{ borderTop: `6px solid ${Colors.borderGray}`, textAlign: 'center', margin: 0 }}>
                        <div className="mb-3 mt-2">
                            <strong>{t("recalls:subscribe.moreInfo.title")}</strong>
                        </div>
                        <div className="mb-3">
                            <img src={artVinLicensePlate} style={{ width: 350, height: 'auto' }} alt="" aria-hidden />
                        </div>
                        <div className="mb-2 lh-md" style={{ textAlign: 'left' }}>
                            <span>{t("recalls:subscribe.moreInfo.text")}</span>
                        </div>
                    </div>
                }
            </ModalFooter>
        </Modal>

    )
}

const InputSection = (props) => {

    const { t } = useTranslation()

    const { icon, value, setValue, error, placeholder, disabled, infoAction, code, toggleError, errorToggler, type } = props

    return (
        <div>
            <div className="input-group rounded-0" style={{ position: 'relative' }}>
                <div className="input-group-prepend">
                    <span
                        className="input-group-text rounded-0"
                        style={{
                            border: error ? `1px solid ${Colors.error}` : `1px solid ${Colors.borderGray}`,
                            // borderBottom: error ? `1px solid ${Colors.error}` : 0,
                            backgroundColor: error ? Colors.error : Colors.lightGray,
                        }}
                    >
                        <img src={icon} alt={""} style={{ width: 36, height: 'auto' }} />
                    </span>
                </div>
                <TextInput
                    // className={`form-control border-right-0 ${props.invalid && 'is-invalid'}`}
                    className={`form-control`}
                    placeholder={placeholder || '-'}
                    maskChar=" "
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    type={type || "text"}
                    code={code || "string"}
                    style={{
                        borderRadius: 0,
                        fontSize: "15px",
                        fontFamily: "Open Sans",
                        borderTop: error ? `1px solid ${Colors.error}` : `1px solid ${Colors.borderGray}`,
                        borderLeft: error ? `1px solid ${Colors.error}` : `1px solid ${Colors.borderGray}`,
                        borderBottom: error ? `1px solid ${Colors.error}` : 0,
                        backgroundColor: error ? Colors.errorBg : (disabled && Colors.lightGray),
                        color: error && Colors.errorDark,
                        height: 'auto',
                    }}
                    disabled={disabled}
                    toggleError={toggleError}
                    errorToggler={errorToggler}
                />
                {
                    infoAction &&
                    <button style={infoIconStyle} onClick={infoAction}>
                        <img src={infoIcon} alt="" style={{ height: 24, width: 24 }} />
                    </button>
                }

            </div>
        </div>
    )

}

const infoIconStyle = {
    position: 'absolute',
    right: 20,
    top: 20,
    border: 0,
    backgroundColor: 'transparent',
    zIndex: 10
}

export default SubscribeModal