import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import artUnsubscribe from '../../../../assets/images/recalls/Art_Unsubscribe.svg'

const RecallsUnsubscribeConfirmation = () => {

    const { t } = useTranslation()

    const history = useHistory()

    return (
        <div className='container'>
            <div className='text-center pb-5'>
                <div className='mt-5 mb-3'>
                    <img src={artUnsubscribe} alt='Unsubscribe' style={{ width: 285 }} />
                </div>
                <div className='mb-3'>
                    <strong className='text-uppercase'>{t('recalls:unsubscribe.confirmation.title')}</strong>
                </div>
                <div className='mb-5'>
                    <span>{t('recalls:unsubscribe.confirmation.text')}</span>
                </div>
                <div className='d-flex justify-content-center'>
                    <button 
                    onClick={() => history.push('/recalls')}
                    style={{ maxWidth: 240}} className='btn btn-cesco btn-sm btn-block'>{t('recalls:unsubscribe.confirmation.button')}</button>
                </div>
            </div>
        </div>
    )
}

export default RecallsUnsubscribeConfirmation