import React from "react"
import ComponentTitle from "../../../components/ui/ComponentTitle"
import Card from "../../../components/ui/Card"
import subscribeArt from '../../../assets/images/recalls/subscribe_art.png'
import { useTranslation } from "react-i18next"
import Button from "../../../components/ui/Button"
import { Colors } from "../../../Colors"

const SubscribeComponent = (props) => {

    const { setSubscribeModal } = props

    const { t } = useTranslation()

    return (
        <div className="container">
            <div className="row mb-5">
                <div className="col-12">
                    <Card>
                        <div className="row p-3 p-lg-0">
                            <div className="col-12 col-lg-3 justify-content-center mb-2 mb-lg-0 d-none d-lg-flex">
                                <img src={subscribeArt} alt="subscribe-art" style={{ width: 280 }} />
                            </div>
                            <div className="col-12 col-lg-6 align-self-center text-left mb-3 mb-lg-0">
                                <div className="mb-1">
                                    <span className="text-md text-bold">{t("recalls:subscribe.title")}</span>
                                </div>
                                <div>
                                    <span>{t("recalls:subscribe.text")}</span>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3 d-flex align-items-center justify-content-end">
                                <Button
                                    disabled={false}
                                    className="btn btn-cesco btn-block"
                                    onClick={() => setSubscribeModal(true)}
                                >{t("recalls:subscribe.button")}</Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default SubscribeComponent