import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/**
 * @typedef {object} ButtonProps
 * @property {boolean} disabled - Indicates if the button is disabled.
 * @property {string} className - The class name of the button.
 * @property {import("react").CSSProperties} style - The style of the button.
 * @property {function} onClick - The click event handler of the button.
 * @property {string} label - The label of the button.
 * @property {React.ReactNode} children - The children of the button.
 */

/**
 * Button component.
 * @param {ButtonProps} props - The props of the button.
 * @returns {JSX.Element} - The rendered button component.
 */
const Button = (props) => {
    return (
        <button
            type={props.type || "button"}
            role="button"
            style={{
                height: "45px",
                borderRadius: "4px",
                minWidth: "3rem",
                minHeight: "2rem",
                ...props.style,
            }}
            aria-disabled={props.disabled}
            className={`btn btn-sm text ${props.className}`}
            disabled={props.disabled}
            aria-label={props.label}
            onClick={() =>
                !props.disabled && props.onClick ? props.onClick() : () => {}
            }
        >
            {props.children}
        </button>
    );
};
Button.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.any,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    disabled: true,
};

export default Button;
