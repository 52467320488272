import React, { useCallback, useEffect } from "react";
import AppStoresBadges from "../../components/ui/AppStoresBadges";
import { isAndroid, isIOS } from "react-device-detect";
import Button from "../../components/ui/Button";
import { Colors } from "../../Colors";
import Text from "../../components/ui/Text";
import { isMobile } from "../../core/Utils";

import background from './assets/OtrasTransacciones_BG2.jpg'
import deeplinkArt from './assets/Art_Deeplink.svg'
import { useTranslation } from "react-i18next";

const Deeplink = () => {
    const params = window.location.search;
    const iosUrl = `cescodigital://dl${params}`;
    const androidUrl = `intent://dl${params}/#Intent;scheme=cescodigital;package=${process.env.REACT_APP_ANDROID_DEEP_LINK_PACKAGE};end`;

    const androidStore = "https://play.google.com/store/apps/details?id=com.dtop_mobile"
    const iosStore = "https://apps.apple.com/us/app/cesco-digital/id1389951990?mt=8"

    const { t } = useTranslation('deeplink');

    const checkUrl = useCallback(() => {
        if (!isIOS && !isAndroid) return null;
        if (isIOS) {
            return iosUrl;
        }
        if (isAndroid) {
            return androidUrl;
        }
    }, [androidUrl, iosUrl]);

    /**
     * On IOS it will redirect to app, if app is not installed, then it will fallback to
     * apple store. On android, the `href` in the button should be intelligent enough.
     * @type {function(): void}
     */
    const redirect = useCallback(() => {
        try {
            let deeplink = isIOS ? iosUrl : androidUrl

            if(window.location.href = deeplink){
                window.location.replace(deeplink)
                return;
            }else
                alert("Falló la redirección")
        } catch (e) {
            alert("Error")
            console.log(e)
        }
        // setTimeout(function () {
        //     // window.location.replace("/");
        //     if (isIOS) {
        //         window.location.replace('https://apps.apple.com/us/app/cesco-digital/id1389951990?mt=8')
        //     } else if(isAndroid){
        //         window.location.replace('https://play.google.com/store/apps/details?id=com.dtop_mobile')
        //     }else
        //         window.location.replace("/");
        //     }, 2000);

    }, [androidUrl, iosUrl]);

    useEffect(() => {
        redirect();
    }, [redirect]);

    if (isMobile()) return (
        <div className="container">
            <AppStoresBadges />
            {checkUrl() && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <a href={isIOS ? iosStore : androidStore} onClick={redirect}>
                        <Button
                            style={{
                                backgroundColor: Colors.darkGreen,
                                minWidth: 200,
                            }}
                            disabled={false}
                        >
                            <Text
                                style={{ color: "white" }}
                                i18nKey={"wizardFooter:continue"}
                            />
                        </Button>
                    </a>
                </div>
            )}
        </div>
    );


    return (
        <div className="container py-5 d-flex flex-column align-items-center"  style={{ color: Colors.darkGray }}>
            <div style={{ textAlign: 'center' }}>
                <span className="text-xl">{t('deeplink:title.0')} <br /> {t('deeplink:title.1')}</span>
            </div>

            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={deeplinkArt} alt="Deeplink Art" style={{ position: 'absolute', width: '60%' }} />
                <img src={background} alt="Deeplink Background" style={{ width: '100%' }} />
            </div>

            <div className="w-lg-50" style={{ textAlign: 'center'}}>
                <span>{t('deeplink:text')}</span>
            </div>
        </div>
    )
};

export default Deeplink;
