import React, { useEffect, useRef, useState } from 'react'
import SubHeader from '../../../layout/SubHeader'
import PageTitle from '../../../components/ui/PageTitle'
import Text from '../../../components/ui/Text'
import { Colors } from '../../../Colors'
import { useLocation, useHistory } from "react-router-dom";
import VehicleInfoRow from '../../Insurance/components/VehicleInfoRow'
import Card from '../../../components/ui/Card'

import mailIcon from '../../../assets/images/recalls/mail_icon.png'
import vinIcon from '../../../assets/images/recalls/vin_number_icon.png'
import numberIcon from '../../../assets/images/recalls/numbers.png'
import retirosIcon from '../../../assets/images/recalls/icon_retiros.svg'
import WizardFooter from '../../../components/widgets/WizardFooter'
import { useTranslation } from 'react-i18next'
import Recaptcha from '../../Login/Recaptcha'
import { loadReCaptcha } from 'react-recaptcha-v3'
import store from '../../../store'
import ErrorMessage from '../components/ErrorMessage'
import LoadingSpinner from '../../../components/ui/LoadingSpinner'

const RecallsUnsubscribe = () => {

    const RECALLS_API_ENDPOINT = process.env.REACT_APP_RECALLS_API

    // Recaptcha
    const recaptchaRef = useRef();

    useEffect(() => {
        loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    }, []);

    useEffect(() => {
        const { current } = recaptchaRef;
        current.execute();
    }, []);

    const { t } = useTranslation()

    const history = useHistory()
    const location = useLocation()

    const params = new URLSearchParams(location.search)

    const queryEmail = params.get("email")

    const vehicleYear = params.get("year")
    const vehicleMake = params.get("make")
    const vehicleModel = params.get("model")
    const vehiclePlate = params.get("plate")
    const vehicleVin = params.get("vin")
    const registrationNumber = params.get("registrationNumber")

    const vehicle = {
        year: vehicleYear,
        make: vehicleMake,
        model: vehicleModel,
        plate: vehiclePlate,
    }

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleError = () => {
        setError(true)
        setTimeout(() => {
            setError(false)
        }, 5000)
    }

    const handleUnsubscribe = () => {
        setLoading(true)
        const unsubscribeData = {
            vin: vehicleVin,
            email: queryEmail,
            hashedRegistrationNumber: registrationNumber
        };

        let recaptcha = store.getState().recaptcha;

        fetch(`${RECALLS_API_ENDPOINT}/recalls/unsubscribe`, {
            method: 'POST',
            headers: {
                'recaptcha-token': recaptcha.token
            },
            body: JSON.stringify(unsubscribeData)
        })
            .then(response => {
                if (!response.ok) {
                    handleError()
                    return
                }

                history.push("/recalls/unsubscribe/confirmation");
            })
            .catch(error => {
                handleError()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const RenderBody = () => {

        if (loading) {
            return (
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center my-5">
                        <LoadingSpinner size={150}  />
                    </div>
                    </div>
                </div>
            )
        }

        return (
            <div className='row'>
                <div className='col-12'>
                    <Card noPadding>
                        <CardRowItem
                            icon={mailIcon}
                            label={t("recalls:unsubscribe:email")}
                            value={queryEmail || '-'}
                        />

                        {/* <CardRowItem
                                icon={vinIcon}
                                label={"Número Vin"}
                                value={'12345WBG9870246989'}
                            /> */}

                        {/* <CardRowItem
                                icon={numberIcon}
                                label={"Número de Registro"}
                                value={'12345678'}
                            /> */}
                    </Card>
                </div>
            </div>
        )
    }

    return (
        <div>
            <SubHeader>
                <div className="container">
                    <PageTitle i18nKey={"recalls:unsubscribe:title"} />
                    <div className="d-none d-lg-flex">
                        <hr />
                    </div>
                    <Text
                        i18nKey="recalls:unsubscribe:subTitle"
                        className="text lh-md"
                        style={{ color: Colors.gray }}
                    />
                </div>
            </SubHeader>



            <div className='container'>

                <div className='row'>
                    <div className='col-12'>
                        <div className='mt-3'>
                            <VehicleInfoRow
                                vehicle={vehicle}
                            />
                        </div>
                        <div className='mt-3 mb-7'>
                            <div style={{ borderBottom: `1px solid ${Colors.borderDarkGray}` }} />
                        </div>
                    </div>
                </div>

                <RenderBody />

                {
                    error &&
                    <div className='row'>
                        <div className='col-12 mt-3'>
                            <ErrorMessage text={t("recalls:unsubscribe:error")} rounded={true} />
                        </div>
                    </div>
                }

                <div className='row'>
                    <div className='col-12'>
                        <div className='d-flex justify-content-between align-items-center py-5'>
                            <button className='btn btn-sm' onClick={() => history.push("/recalls")}>
                                <img src={retirosIcon} alt="icon" style={{ height: 24 }} className='mr-2' />
                                {t("recalls:unsubscribe:backButton")}
                            </button>
                            <button
                                onClick={() => handleUnsubscribe()}
                                disabled={!queryEmail || !vehicleVin || !registrationNumber || loading}
                                className='btn btn-cesco btn-sm'>
                                {loading ? t("common:loading") : t("recalls:unsubscribe:cancelButton")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Recaptcha forwardRef={recaptchaRef} action="" />
        </div>
    )
}

const CardRowItem = (props) => {

    const { icon, label, value } = props

    return (
        <div className='d-flex align-items-center border-bottom p-3'>
            <div className='mr-3'>
                <img src={icon} alt="icon" style={{ width: '36px', height: '36px' }} />
            </div>
            <div className='d-flex flex-column'>
                <small style={{ textTransform: 'uppercase', fontSize: '12px', marginBottom: 2 }}>{label}</small>
                <span className='text text-bold'>{value}</span>
            </div>
        </div>
    )
}

export default RecallsUnsubscribe