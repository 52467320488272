import React from "react"
import { Colors } from "../../../Colors"

const TextWithSmLabel = (props) => {

    const { label, text, textBold } = props

    return (
        <div>
            <span style={{ color: Colors.gray, fontSize: 12, textTransform: "uppercase" }}>{label}</span>
            <br />
            <span
                className={`${textBold ? "font-bold" : ""}`}
                style={{ fontSize: 16 }}
            >{text || "-"}</span>
        </div>
    )
}

export default TextWithSmLabel