import React from "react"

const LoadingSpinner = (props) => {

    const { size } = props

    return (
        <div style={spinnerStyle(size)} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}

const spinnerStyle = (size) => ({
    display: "inline-block",
    width: size || "11rem",
    height: size || "11rem",
    verticalAlign: "text-bottom",
    border: "0.35em solid #4e9b63",
    borderRightColor: "transparent",
    borderRadius: "50%",
    WebkitAnimation: "spinner-border 0.75s linear infinite",
    animation: "spinner-border 0.75s linear infinite",
})

export default LoadingSpinner