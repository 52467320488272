import React, {useEffect, useState} from "react";
import iconCardNumber from "../../assets/images/icon-card-number.svg";
import Icon from "./Icon";
import InputMask from "react-input-mask";
import CreditCardIcon from "./CreditCardIcon";
import {Colors} from "../../Colors";
import {useTranslation} from "react-i18next";

function cardTypeFromNumber(number) {
    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null) return "Diners - Carte Blanche";

    // Diners
    re = new RegExp("^(30[6-9]|36|38)");
    if (number.match(re) != null) return "Diners";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null) return "JCB";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null) return "AMEX";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null) return "Visa Electron";

    // Visa
    var re = new RegExp("^4");
    if (number.match(re) != null) return "VISA";

    // Mastercard
    re = new RegExp("^5[1-5]");
    if (number.match(re) != null) return "MASTERCARD";

    // Discover
    re = new RegExp(
        "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) return "Discover";

    return "";
}

const VISA_VALID = /^4[0-9]{12}(?:[0-9]{3})?$/;
const MASTERCARD_VALID = /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;

const CreditCardInput = (props) => {
    const [value, handleValue] = useState("");
    const [type, handleType] = useState("");
    const [error, toggleError] = useState(false);

    const {t} = useTranslation();

    useEffect(() => { if (props.value) handleValue(props.value) }, [props?.value]);

    useEffect(() => {
        if (props.onChange) {
            let str = value.replace(/\s/g, '');
            props.onChange(str)
        }
    }, [value])

    const handleValidation = () => {
        let type = value ? cardTypeFromNumber(value) : "";

        handleType(type);

        if (type && value &&
            (VISA_VALID.test(value.replace(/\s/g, "")) ||
                MASTERCARD_VALID.test(value.replace(/\s/g, "")))
        ) {
            props.onValid(value.replace(/\s/g, ""));
            toggleError(false)
            props.errorToggler(false)
        } else {
            toggleError(true)
            props.errorToggler(true)
        }
    }

    return (
        <div className="input-group rounded-0">
            <div className="input-group-prepend">
                <span
                    className="input-group-text border-left-0 rounded-0"
                    style={{
                        borderTop: error && `1px solid ${Colors.error}`,
                        borderBottom: error && `1px solid ${Colors.error}`,
                        backgroundColor: error ? Colors.error : Colors.lightGray
                    }}
                >
                    <Icon src={iconCardNumber} alt={""}/>
                </span>
            </div>
            <InputMask
                className={`form-control border-right-0 ${props.invalid && 'is-invalid'}`}
                mask="9999 9999 9999 9999"
                placeholder={t("payFines:form.cardNumber")}
                maskChar=" "
                onChange={(e) => handleValue(e.target.value)}
                onBlur={() => handleValidation()}
                value={value}
                type="text"
                pattern="[0-9 ]*"
                inputMode="numeric"
                style={{
                    borderBottomRightRadius: 0,
                    fontSize: "15px",
                    fontFamily: "Open Sans",
                    border: error && `1px solid ${Colors.error}`,
                    backgroundColor: error && Colors.errorBg,
                    color: error && Colors.errorDark
                }}
            />
            <CreditCardIcon
                paymentMethod={type}
                style={{position: "absolute", zIndex: 10 }}
                className="creditCardPadding"
            />
        </div>
    );
};

export default CreditCardInput;
