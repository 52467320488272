import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, withRouter, useLocation, useHistory } from "react-router-dom";
import homeIcon from "../assets/images/Home.svg";
import cescoDigitalLogo from "../assets/images/LogoCesco.svg";
import BurgerMenu from "./BurgerMenu";
import NavPage from "./NavPage";

import iconNotification from "../assets/images/icon-notification-white.svg";
import DisabledPayments from "../components/ui/DisabledPayments";

import recallsIcon from "../assets/images/recalls/Retiros.svg";

const Header = (props) => {
    const [zoomed, setZoomed] = useState(false);
    const config = useSelector((state) => state.config);
    const maintenance = useSelector((state) => state.features?.config?.web?.maintenance);
    const { user } = useSelector((state) => state.login);
    const authenticated = useSelector((state) => state?.login?.authenticated);
    const { t } = useTranslation();
    const showBurgerMenu = authenticated;

    const acceptPayment = useSelector(s => s?.features?.payments?.enabled)

    const { lang, changeLang } = props

    const history = useHistory();
    const { pathname } = useLocation();

    const isLogin = pathname === '/login'

    const recallsEnabled = useSelector(state => state.features?.recalls?.enabled)

    const RenderActionButton = () => {

        if (!recallsEnabled) return null

        if (isLogin) return (
            <button className="btn btn-cesco-dark btn-sm mr-3"
                onClick={() => history.push("/recalls")}
            >
                <img src={recallsIcon} alt="Navigate to Recalls Section" style={{ height: 24, width: 24 }} />
                <span className="ml-2" style={{ fontSize: 16 }}> RECALLS </span>
            </button>
        )

        return (
            <Link
                to={authenticated ? "/landing" : "/login"}
                role={"button"}
                aria-label={t(
                    "layout:header.arias.homeIcon"
                )}
                className="nav-link text-light pointer d-none d-lg-inline-block "
            >
                <img
                    src={homeIcon}
                    aria-hidden="true"
                    style={{
                        height: 16,
                        marginRight:
                            "5px",
                        marginTop: '-3px'
                    }}
                    alt=""
                />
            </Link>
        )
    }

    return (
        <>
            {config &&
                !acceptPayment &&
                !maintenance &&
                authenticated &&
                !props.showLogoOnly && <DisabledPayments />}

            <header
                id="header"
                className="u-header u-header--bg-transparent u-header--abs-top"
                alt="Cesco digital header"
            >
                <div className="u-header__section">
                    <div className="container">
                        <nav className="navbar navbar-expand u-header__navbar">
                            <div className="d-flex justify-content-between w-100">
                                <Link
                                    className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center u-header__navbar-brand-text-white"
                                    to={authenticated ? "/landing" : "/login"}
                                    role={"button"}
                                    aria-label={t(
                                        "layout:header.arias.cescoLogoHome"
                                    )}
                                >
                                    <img
                                        aria-hidden="true"
                                        src={cescoDigitalLogo}
                                        className="cescoLogo"
                                        alt="Cesco digital logo"
                                    />
                                </Link>

                                {!props.showLogoOnly && (
                                    <ul className="navbar-nav u-header--starter text-nowrap flex-row align-items-center ml-0 ml-sm-3">
                                        <li className="nav-item ">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <RenderActionButton />

                                                <a
                                                    className="text"
                                                    onClick={() => {
                                                        changeLang("es-US");
                                                    }}
                                                    role={"button"}
                                                    aria-label={t(
                                                        "layout:header.arias.spanish"
                                                    )}
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        textDecoration:
                                                            lang === "es-US"
                                                                ? "underline"
                                                                : "none",
                                                        fontWeight:
                                                            lang === "es-US"
                                                                ? "bold"
                                                                : "",
                                                    }}
                                                >
                                                    ESP
                                                </a>
                                                <span
                                                    className="text"
                                                    aria-hidden={true}
                                                    style={{
                                                        color: "#fff",
                                                        margin: "0 5px 0 5px",
                                                    }}
                                                >
                                                    |
                                                </span>
                                                <a
                                                    className="text"
                                                    aria-label={t(
                                                        "layout:header.arias.english"
                                                    )}
                                                    role={"button"}
                                                    onClick={() => {
                                                        changeLang("en-US");
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        textDecoration:
                                                            lang === "en-US"
                                                                ? "underline"
                                                                : "none",
                                                        fontWeight:
                                                            lang === "en-US"
                                                                ? "bold"
                                                                : "",
                                                    }}
                                                >
                                                    ENG
                                                </a>
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            {authenticated && (
                                                <Link
                                                    to={"/messages"}
                                                    role={"button"}
                                                    aria-label={t(
                                                        `layout:header.arias.notifications.${user?.unreadNotifications >
                                                            0
                                                            ? "withNotifications"
                                                            : "noNotifications"
                                                        }`
                                                    )}
                                                    className={`nav-link text-light pointer ${user?.unreadNotifications >
                                                        0
                                                        ? "animation-wrapper"
                                                        : ""
                                                        }`}
                                                    style={{
                                                        margin: "0 5px 0 5px",
                                                    }}
                                                >
                                                    <img
                                                        src={iconNotification}
                                                        alt=""
                                                        aria-hidden="true"
                                                        className={`${user?.unreadNotifications >
                                                            0
                                                            ? "notificationAnimation"
                                                            : ""
                                                            }`}
                                                    />
                                                    <span
                                                        alt={
                                                            "layout:header.arias.unreadNotifications"
                                                        }
                                                        className={`${user?.unreadNotifications >
                                                            0
                                                            ? "red-dot"
                                                            : ""
                                                            }`}
                                                    />
                                                </Link>
                                            )}
                                        </li>

                                        <li className="nav-item d-none d-lg-block">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    color: "#fff",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                <a
                                                    role={"button"}
                                                    aria-label={t(
                                                        "layout:header.arias.pageZoom"
                                                    )}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        document.body.style.zoom = zoomed
                                                            ? "100%"
                                                            : "120%";
                                                        setZoomed(!zoomed);
                                                    }}
                                                >
                                                    A
                                                    <span
                                                        aria-hidden={true}
                                                        style={{
                                                            fontSize: 22,
                                                            marginLeft: 2,
                                                            fontWeight: "bold",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        A
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                        {showBurgerMenu && <BurgerMenu />}
                                    </ul>
                                )}
                            </div>
                        </nav>
                    </div>
                </div>
            </header>

            <NavPage history={props.history} />
        </>
    );
};

Header.propTypes = {
    showLogoOnly: PropTypes.bool,
    lang: PropTypes.string,
    changeLang: PropTypes.func,
};

Header.defaultProps = {
    showLogoOnly: false,
};
export default withRouter(Header);
