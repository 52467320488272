import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'

export const usePageTracking = () => {

  const { pathname, search, hash } = useLocation();
  const { i18n } = useTranslation()
  const lang = i18n.language === 'es-US' ? 'es_us' : 'en_us'

  useEffect(() => {

    if (window.location.href.includes("localhost")) {
      console.log('Local log: ', pathname + search);
      return
    }

    window.gtag("event", "page_view", {
      page_location: pathname + search + hash,
      language: lang
    });
  }, [pathname, search, hash]);
};

export const logRecallsSearchEvent = (searchParam) => {
  console.log('logRecallsSearchEvent: ', searchParam);
  window.gtag("event", "recalls_search", {
    search_param: searchParam,
  });
}
