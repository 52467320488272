import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Colors } from "../../Colors";
import { FontWeight } from "../../components/ui/FontSize";
import PageTitle from "../../components/ui/PageTitle";
import Text from "../../components/ui/Text";
import TabNavigation from "../../components/widgets/TabNavigation";
import SubHeader from "../../layout/SubHeader";

import { loadReCaptcha } from "react-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import searchIcon from "../../assets/images/recalls/search-icon.svg";
import TextInput from "../../components/ui/TextInput";
import { scrollToDiv } from "../../core/Utils";
import { setRecaptcha } from "../../redux/app/app.combine";
import Recaptcha from "../Login/Recaptcha";
import ErrorMessage from "./components/ErrorMessage";
import RecallsContent from "./components/RecallsContent";
import SubscribeComponent from "./components/SubscribeComponent";
import SubscribeModal from "./components/SubscribeModal";
import SubscriptionSuccessModal from "./components/SubscriptionSuccessModal";
import WhereVinNumberCard from "./components/WhereVinNumberCard";
import "./recalls.css";
import RecallsFaq from "./components/RecallsFaq";
import { logRecallsSearchEvent } from "../../analytics/tracking";

const Recalls = () => {
    const RECALLS_API_ENDPOINT = process.env.REACT_APP_RECALLS_API;

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [searchError, setSearchError] = useState(false);

    const [activeTab, setActiveTab] = useState(0);

    const [result, setResult] = useState(null);

    const [subscribeModal, setSubscribeModal] = useState(false);
    const [subscribeSuccessModal, setSubscribeSuccessModal] = useState(false);

    const [plate, setPlate] = useState("");
    const [vin, setVin] = useState("");

    const [plateError, setPlateError] = useState(false);
    const [vinError, setVinError] = useState(false);

    const [vinInfoCollapsed, setVinInfoCollapsed] = useState(true);

    const [disabledSearch, setDisabledSearch] = useState(false);

    const recallsEnabled = useSelector(
        (state) => state.features?.recalls?.enabled
    );

    const history = useHistory();

    useEffect(() => {
        if (recallsEnabled === false) {
            history.push("/");
        }
    }, [recallsEnabled]);

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const queryPlate = params.get("plate");
    const queryVin = params.get("vin");

    useEffect(() => {
        if (queryPlate) {
            setActiveTab(0);
            setPlate(queryPlate);
        }
        if (queryVin) {
            setActiveTab(1);
            setVin(queryVin);
        }
    }, [queryPlate, queryVin]);

    const changeTab = (tab) => {
        setPlate("");
        setVin("");
        setPlateError(false);
        setVinError(false);
        setResult(null);
        setActiveTab(tab);
    };

    const [shouldSearch, setShouldSearch] = useState(false);
    const recaptchaToken = useSelector((state) => state.recaptcha.token);
    const dispatch = useDispatch();

    const recaptchaRef = useRef();

    useEffect(() => {
        loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    }, []);

    const handleExecuteRecaptcha = async () => {
        dispatch(setRecaptcha(null));
        const { current } = recaptchaRef;
        await current.execute();
    };

    const initiateSearch = async () => {
        setDisabledSearch(true);
        setResult(null);
        setLoading(true);
        setSearchError(false);
        await handleExecuteRecaptcha();
        setShouldSearch(true);
    };

    useEffect(() => {
        const performSearch = async () => {
            if (!recaptchaToken) return;
            if (!shouldSearch) return;

            let config = { headers: { "recaptcha-token": recaptchaToken } };

            const handleResponse = (res) => {
                // Handle 404 error
                if (res.status === 404) {
                    setSearchError("404");
                    return;
                }

                // Handle any other !ok error
                if (res.ok === false) {
                    setSearchError(true);
                    return;
                }

                // Handle any other !ok error
                if (res.ok === false) {
                    setSearchError(true);
                    return;
                }

                res.json().then(data => {
                    // Handle VEHICLE_NOT_ELIGIBLE error
                    if (data.searchStatus === 'VEHICLE_NOT_ELIGIBLE') {
                        setSearchError('VEHICLE_NOT_ELIGIBLE');
                        return;
                    }

                    setResult(data);
                    setVinInfoCollapsed(false);
                });

                scrollToDiv("recallsContent", true);
            };

            try {
                if (activeTab === 0) {
                    const res = await fetch(
                        `${RECALLS_API_ENDPOINT}/recalls/search?plate=${plate}`,
                        config
                    );
                    handleResponse(res);
                    logRecallsSearchEvent("PLATE");
                } else {
                    let parsedVin = vin.toUpperCase();
                    const res = await fetch(
                        `${RECALLS_API_ENDPOINT}/recalls/search?vin=${parsedVin}`,
                        config
                    );
                    handleResponse(res);
                    logRecallsSearchEvent("VIN");
                }
            } catch (err) {
                setSearchError(true);
            } finally {
                setLoading(false);
                setDisabledSearch(false);
                setShouldSearch(false);
            }
        };

        performSearch();
    }, [recaptchaToken, shouldSearch, activeTab, plate, vin]);

    const tabNavigationItems = [
        {
            i18nKey: t("recalls:tabs.plate"),
        },
        {
            i18nKey: t("recalls:tabs.vin"),
        },
    ];

    const searchInputError = plateError || vinError;

    const toggleError = (errorToggler, bool) => errorToggler(bool);

    const renderErrorMessage = () => {
        if (plateError) {
            return t("recalls:search.error.plate");
        }
        if (vinError) {
            return t("recalls:search.error.vin");
        }
    };

    const RenderErrorMessage = () => {
        if (!searchInputError) return;

        if (activeTab === 0 && plate?.length <= 2) return;
        if (activeTab === 1 && vin?.length <= 2) return;

        return (
            <div className="mt-2">
                <ErrorMessage text={renderErrorMessage()} rounded={true} />
            </div>
        );
    };

    return (
        <div>
            <SubHeader>
                <div className="container">
                    <PageTitle i18nKey={"recalls:title"} />
                    <div className="d-none d-lg-flex">
                        <hr />
                    </div>
                    <Text
                        i18nKey="recalls:subTitle"
                        className="text lh-md"
                        style={{ color: Colors.gray }}
                    />
                </div>
            </SubHeader>

            <div className="container">
                <div className="row">
                    <div className="col-12 px-3">
                        <div className="d-flex justify-content-center mb-3 mt-5">
                            <Text
                                className={"text-md"}
                                style={{
                                    fontWeight: FontWeight.bold,
                                    letterSpacing: "0.03rem",
                                    color: Colors.darkGray,
                                }}
                                i18nKey={"recalls:enterNumber.title"}
                            />
                        </div>

                        <div className="mb-3">
                            <TabNavigation
                                activeTab={activeTab}
                                changeTab={changeTab}
                                tabNavigationItems={tabNavigationItems}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mb-10">
                <div className="row">
                    <div className="col">
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                boxShadow: "0px 10px 30px 0px #33333326",
                            }}
                            className="input-group"
                        >
                            {activeTab === 0 && (
                                <TextInput
                                    onKeyPress={(e) =>
                                        e.key === "Enter" && initiateSearch()
                                    }
                                    type="search"
                                    code="plate"
                                    className="form-control text searchField"
                                    aria-label={t(
                                        "recalls:tabs.platePlaceholder"
                                    )}
                                    placeholder={t(
                                        "recalls:tabs.platePlaceholder"
                                    )}
                                    value={plate}
                                    onChange={(e) => {
                                        setPlate(e.target.value);
                                        if (disabledSearch) {
                                            setDisabledSearch(false);
                                        }
                                    }}
                                    style={{
                                        textTransform: "uppercase",
                                    }}
                                    disabled={loading}
                                    toggleError={toggleError}
                                    errorToggler={setPlateError}
                                />
                            )}

                            {activeTab === 1 && (
                                <TextInput
                                    onKeyPress={(e) =>
                                        e.key === "Enter" && initiateSearch()
                                    }
                                    type="search"
                                    code="vin"
                                    className="form-control text searchField"
                                    aria-label={t(
                                        "recalls:tabs.vinPlaceholder"
                                    )}
                                    placeholder={t(
                                        "recalls:tabs.vinPlaceholder"
                                    )}
                                    value={vin}
                                    onChange={(e) => {
                                        setVin(e.target.value);
                                        if (disabledSearch) {
                                            setDisabledSearch(false);
                                        }
                                    }}
                                    style={{
                                        textTransform: "uppercase",
                                    }}
                                    disabled={loading}
                                    toggleError={toggleError}
                                    errorToggler={setVinError}
                                />
                            )}

                            <div className="input-group-append">
                                <button
                                    id="recalls-search-button"
                                    className="btn btn-cesco btn-block"
                                    onClick={initiateSearch}
                                    disabled={
                                        searchInputError ||
                                        loading ||
                                        (activeTab === 0
                                            ? plate === ""
                                            : vin === "") ||
                                        disabledSearch
                                    }
                                    style={{
                                        backgroundColor: searchInputError
                                            ? Colors.error
                                            : Colors.primary,
                                        border: searchInputError
                                            ? `1px solid ${Colors.error}`
                                            : `1px solid ${Colors.borderDarkGray}`,
                                    }}
                                >
                                    {loading ? (
                                        <div
                                            className="spinner-border-loading"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ width: 20, height: 20 }}
                                        ></div>
                                    ) : (
                                        <img
                                            src={searchIcon}
                                            alt="search"
                                            style={{ width: 20 }}
                                        />
                                    )}
                                </button>
                            </div>
                        </div>

                        {RenderErrorMessage()}
                    </div>
                </div>
            </div>

            {!searchError && !loading && (
                <div className="container">
                    <WhereVinNumberCard
                        vinInfoCollapsed={vinInfoCollapsed}
                        setVinInfoCollapsed={setVinInfoCollapsed}
                    />
                </div>
            )}

            {result && (
                <SubscribeComponent setSubscribeModal={setSubscribeModal} />
            )}

            <div id="recallsContent">
                <RecallsContent
                    result={result}
                    searchError={searchError}
                    loading={loading}
                />
            </div>

            <RecallsFaq />

            {subscribeModal && (
                <SubscribeModal
                    title={t("recalls:subscription")}
                    toggle={() => setSubscribeModal(false)}
                    action={() => {
                        setSubscribeModal(false);
                        setSubscribeSuccessModal(true);
                    }}
                    vehicleInfo={result?.vehicle}
                    recaptchaRef={recaptchaRef}
                />
            )}
            {subscribeSuccessModal && (
                <SubscriptionSuccessModal
                    toggle={() => setSubscribeSuccessModal(false)}
                />
            )}

            <Recaptcha forwardRef={recaptchaRef} action="" />
        </div>
    );
};

export default Recalls;
