import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Utils from "../../core/Utils";
import { Colors } from "../../Colors";
import { useSelector } from "react-redux";



// [^0-9~!@#$%^&*()_+={}[]|\\"':;?.<>]
let rangeFrom0to15 = /^([0-9]|[1][0-5])$/;
let onlyLetter = /^[a-zA-Z]*$/;
let onlyLetterAndSpace = /^[a-zA-Z ]*$/;
let namesRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$/;
let onlyNumber = /^[0-9]*$/;
let onlyLetterAndNumber = /^[a-zA-Z0-9 ]*$/;
let alphanumeric = /^[a-zA-Z0-9]*$/;
let emailAcceptedChar = /^\S*$/;
let emailValidation = /[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})/;

const TextInput = (props) => {

    const [localValue, setLocalValue] = useState('')
    const [error, setError] = useState()
    const [config, setConfig] = useState()

    const paymentCardHolderNameLength = useSelector(state => state?.features?.payments?.paymentCardHolderNameLength)

    let fields = {
        string: { min: 1, max: 128, regex: onlyLetter },
        number: { min: 1, max: 6, regex: onlyNumber },
        creditCardName: { min: 3, max: paymentCardHolderNameLength || 25, regex: namesRegex },
        name: { min: 3, max: 32, regex: namesRegex },
        lastName: { min: 1, max: 128, regex: namesRegex },
        email: { min: 1, max: 200, regex: emailValidation },
        lastSSN: { min: 4, max: 4, regex: onlyNumber },
        day: { min: 1, max: 2, regex: onlyNumber },
        year: { min: 4, max: 4, regex: onlyNumber },
        zipcode: { min: 5, max: 5, regex: onlyNumber },
        addressLine: { min: 1, max: 40, regex: onlyLetterAndNumber },
        addressLine2: { min: 0, max: 10, regex: onlyLetterAndNumber },
        password: { min: 4, max: 20, regex: emailAcceptedChar },
        text: { min: 10, max: 50, regex: onlyLetterAndNumber },
        cvc: { min: 3, max: 4, regex: onlyNumber },
        phone: { min: 10, max: 11, regex: onlyNumber },
        registrationNumber: { min: 6, max: 12, regex: onlyNumber },
        controlNumber: { min: 6, max: 12, regex: onlyNumber },
        importTotal: { min: 6, max: 12, regex: onlyNumber },
        city: { min: 3, max: 20, regex: onlyLetterAndSpace },
        state: { min: 2, max: 2, regex: onlyLetter },
        licenseNumber: { min: 1, max: 128, regex: onlyLetterAndNumber },
        plate: { min: 3, max: 12, regex: alphanumeric },
        vin: { min: 17, max: 17, regex: alphanumeric }
    };

    useEffect(() => {
        if (props.code && fields[props.code]) {
            setConfig(fields[props.code]);
        }

        if (props?.value?.length > 0) {
            setLocalValue(props.value)
        }

        // setError(hasError(props.value))
    }, [props.code, props.value])

    const inputHandler = (e) => {
        e.persist();

        let value = e.target.value;

        // Sets local value used on input
        setLocalValue(value)

        // Triggers parent component onChange
        if (props.onChange) props.onChange(e);

        // Toggles error on local state
        setError(hasError(value))

        // Toggles error on parent component
        if (props.toggleError) {
            props.toggleError(props.errorToggler, hasError(value))
        }

        // Validates input value length against config
        if (config) {

            if((config?.min === undefined || config?.min === null) || !config?.max) console.error('Min or max not found at validation config.\n(at TextInput.js)')

            const isValueLengthValid = value.length >= config.min && value.length <= config.max;
            if (!isValueLengthValid) {
                setError(true);
            }
        }

    }

    const hasError = (value) => {
        if (!config || !config.regex) {
            return false;
        }

        return !config.regex.test(value);
    }

    let calcProps = {};
    if (config?.max) calcProps.maxLength = config.max;
    if (config?.min) calcProps.minLength = config.min;

    return (<input {...props}
        {...calcProps}
        type={props.type || "text"}
        autoCorrect="none"
        autoCapitalize="none"
        onChange={inputHandler}
        value={localValue}
        style={{
            ...props.style,
            border: error && `1px solid ${Colors.error}`,
            backgroundColor: error ? Colors.errorBg : 'white',
            color: error && Colors.errorDark
        }}
        readOnly={props.readonly}
    />
    );
}

TextInput.propTypes = {
    required: PropTypes.bool,
    includeLabel: PropTypes.bool,
    iconName: PropTypes.string,
    grouped: PropTypes.bool,
    plainInput: PropTypes.bool,
    toggleError: PropTypes.func,
    errorToggler: PropTypes.func,
    code: PropTypes.oneOf([
        'string',
        'number',
        'name',
        'lastName',
        'email',
        'lastSSN',
        'day',
        'year',
        'zipcode',
        'addressLine',
        'password',
        'text',
        'cvc',
        'phone',
        'registrationNumber',
        'controlNumber',
        'importTotal'
    ]),
};

export default TextInput;
