import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Text from "../../../components/ui/Text";
import PageTitle from "../../../components/ui/PageTitle";
import SubHeader from "../../../layout/SubHeader";
import WizardFooter from "../../../components/widgets/WizardFooter";
import { Colors } from "../../../Colors";
import "./style.css";
import MissingDocListItem from "./MissingDocListItem";
import { useState } from "react";
import MissingDocsModal from "./MissingDocsModal";
import progressIcon from "../../../assets/images/icon-tiempo-virtual.svg";
import { FontWeight } from "../../../components/ui/FontSize";

// TODO
// Connect to db

const MissingDocs = () => {
    const [error, toggleError] = useState(true);
    const [inProgress, toggleInProgress] = useState(false);
    const [warning, toggleWarning] = useState(true);
    const [validationModal, toggleValidationModal] = useState(false);
    const [informationModal, toggleInformationModal] = useState(false);

    let history = useHistory();
    // let missingDocs = useSelector((state) => state.renewal?.missingDocs);
    let missingDocs = [
        {
            id: 0,
            title: "Tarjeta de Seguro Social",
            description: "Tomar foto del Seguro Social o agregar archivo",
            confirmed: true,
            error: false,
        },
        {
            id: 1,
            title: "Certificado de Nacimiento",
            description:
                "Tomar foto o agregar archivo del certificado y escribir el numero",
            confirmed: false,
            error: true,
        },
        {
            id: 2,
            title: "Foto",
            description:
                "Tomar foto o agregar archivo del certificado y escribir el numero",
            confirmed: true,
            error: false,
        },
        {
            id: 3,
            title: "Firma",
            description: "Firmar en el recuadro",
            confirmed: false,
            error: true,
        },
    ];

    return (
        <>
            <SubHeader>
                <div className="container">
                    <PageTitle i18nKey="missingDocs:pageTitle" />
                    <div className="d-none d-lg-flex">
                        <hr />
                    </div>
                    {inProgress && (
                        <div style={progressWrapper}>
                            <img
                                src={progressIcon}
                                style={{ height: "32px", marginRight: "10px" }}
                                aria-hidden={true}
                                alt=""
                            />
                            <div style={inProgressStyle}>
                                <Text
                                    i18nKey="missingDocs:inProgressTitle"
                                    className="text"
                                    style={{
                                        color: Colors.gray,
                                        textTransform: "uppercase",
                                        fontWeight: FontWeight.medium,
                                    }}
                                />
                                <Text
                                    i18nKey="missingDocs:inProgressDescription"
                                    className="text"
                                    style={{ color: Colors.gray }}
                                />
                            </div>
                        </div>
                    )}
                    {warning && (
                        <div style={warningStyle}>
                            <Text
                                i18nKey="missingDocs:warningTitle"
                                className="text"
                                style={{
                                    color: Colors.gray,
                                }}
                            />
                            <Text
                                i18nKey="missingDocs:warningText"
                                className="text"
                                style={{ color: Colors.error }}
                            />
                        </div>
                    )}
                </div>
            </SubHeader>

            <div className="container" style={{ padding: 0 }}>
                {missingDocs.map((doc, idx) => (
                    <MissingDocListItem
                        doc={doc}
                        idx={idx}
                        toggleModal={toggleValidationModal}
                        modal={validationModal}
                    />
                ))}
                {error && (
                    <div style={errorBlock}>
                        <Text>Error Text</Text>
                    </div>
                )}
            </div>

            {informationModal && (
                <MissingDocsModal toggle={toggleValidationModal} />
            )}

            {validationModal && (
                <MissingDocsModal toggle={toggleValidationModal} />
            )}

            <div className="container">
                <WizardFooter
                    disabled={false}
                    handleNext={() => history.push("/renewal/type")}
                    hideBack
                />
            </div>
        </>
    );
};

const warningStyle = {
    display: "flex",
    flexDirection: "column",
};

const inProgressStyle = {
    display: "flex",
    flexDirection: "column",
};

const progressWrapper = {
    display: "flex",
    alignItems: "center",
};

const errorBlock = {
    backgroundColor: Colors.error,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
};

export default MissingDocs;
