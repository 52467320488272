import { useTranslation } from "react-i18next"

export const RenderRecallStatus = (status) => {

    const { t } = useTranslation()

    switch (status) {
        case 'RemedyAvailable':
            return t("recalls:statuses.available")
        case 'RemedyNotYetAvailable':
            return t("recalls:statuses.notYetAvailable")
        case 'RemedyNotAvailable':
            return t("recalls:statuses.notAvailable")
        case 'Unknown':
            return t("recalls:statuses.unknown")
        default:
            return 'No Status'
    }
}

export const RenderRecallStatusColor = (status) => {

    switch (status) {
        case 'RemedyAvailable':
            return '#24AAFC'
        case 'RemedyNotYetAvailable':
            return '#E6720A'
        case 'RemedyNotAvailable':
            return '#E6720A'
        case 'Unknown':
            return '#6B6868'
        default:
            return '#6B6868'
    }
}
