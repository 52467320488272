import React from 'react'
import vehicleNotFound from '../../../assets/images/recalls/Art_Recalls_VehiculoNoEncontrado.png'
import ComponentTitle from '../../../components/ui/ComponentTitle'
import { useTranslation } from 'react-i18next'
import Card from '../../../components/ui/Card'

const RecallsSearchError = (props) => {

    const { searchError } = props

    const { t } = useTranslation()

    const RenderErrorText = () => {
        switch (searchError) {
            case '404':
                return t("recalls:vehicle.notFound")
            case 'VEHICLE_NOT_ELIGIBLE':
                return t("recalls:vehicle.notEligible")
            default:
                return t("recalls:vehicle.error")
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <ComponentTitle i18nKey={"recalls:vehicle.title"} bold />
                    <Card>
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <div className="mb-3">
                                <img src={vehicleNotFound} alt="vehicle-not-found" style={{ width: 285 }} />
                            </div>
                            <div>
                                <strong>{RenderErrorText()}</strong>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default RecallsSearchError
