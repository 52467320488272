import React from "react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import PageTitle from "../../../components/ui/PageTitle";
import Text from "../../../components/ui/Text";
import SubHeader from "../../../layout/SubHeader";
import Button from "../../../components/ui/Button";
import { Colors } from "../../../Colors";

import paidFinesImg from "../../../assets/images/multas-pagadas.svg";
import licensePickupImg from "../../../assets/images/recoger-licencia.svg";
import { FontWeight } from "../../../components/ui/FontSize";

const Eligible = () => {
    let history = useHistory();

    const { t } = useTranslation()

    return (
        <>
            <SubHeader>
                <div className="container">
                    <PageTitle
                        style={{ fontWeight: "bold" }}
                        i18nKey={"eligible:pageTitle"}
                    />
                    <hr
                        style={{
                            borderTop: `1px solid ${Colors.borderDarkGray}`
                        }}
                    />
                    <Text
                        i18nKey={"eligible:subTitle"}
                        style={{ color: Colors.gray }}
                    />
                </div>
            </SubHeader>

            <div className="container d-flex flex-column align-items-center">
                <div className="row">
                    <div
                        className="col-lg-6 col-12"
                        style={{
                            ...contentBlock,
                            borderRight: `1px solid ${Colors.borderGray}`
                        }}
                    >
                        <img
                            src={paidFinesImg}
                            style={imgStyle}
                            aria-hidden={true}
                            alt=""
                        />
                        <Text
                            i18nKey={"eligible:paidFines"}
                            style={contentTitle}
                        />
                        <Text
                            i18nKey={"eligible:paidFinesDescription"}
                            style={contentDescription}
                        />
                    </div>
                    <div className="col-lg-6 col-12" style={contentBlock}>
                        <img
                            src={licensePickupImg}
                            style={imgStyle}
                            aria-hidden={true}
                            alt=""
                        />
                        <Text
                            i18nKey={"eligible:licensePickup"}
                            style={contentTitle}
                        />
                        <Text
                            i18nKey={"eligible:licensePickupDescription"}
                            style={contentDescription}
                        />
                    </div>
                </div>
                <Button
                    style={{ width: "25%", marginBottom: "20px" }}
                    disabled={false}
                    onClick={() => history.push('/renewal/type')}
                    className="btn-primary"
                >
                    {t("eligible:footer")}
                </Button>
            </div>
        </>
    );
};

const contentBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "20px"
};

const contentTitle = {
    marginBottom: "20px",
    fontWeight: FontWeight.medium
};

const contentDescription = {
    marginBottom: "20px",
    color: Colors.gray
};

const imgStyle = {
    height: "120px",
    marginBottom: "30px"
};

export default Eligible;
