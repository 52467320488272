import React, { useState } from "react"
import Card from "../../../components/ui/Card"
import Text from "../../../components/ui/Text"

import artVinVehicle from '../../../assets/images/recalls/art_numero_VIN_vehiculo.png'
import artVinLicensePlate from '../../../assets/images/recalls/art_numero_VIN_licencia.png'
import { Colors } from "../../../Colors"
import { Collapse } from "reactstrap"
import { useTranslation } from "react-i18next"
import CaretDropdown from "../../../components/ui/CaretDropdown"

const WhereVinNumberCard = (props) => {

    const { vinInfoCollapsed, setVinInfoCollapsed } = props

    const { t } = useTranslation()

    return (
        <div className="row mb-5">
            <div className="col-12">
                <Card noPadding>
                    <div className="d-flex justify-content-between align-items-center p-3" onClick={() => setVinInfoCollapsed(!vinInfoCollapsed)}
                        style={{
                            borderBottom: vinInfoCollapsed && `1px solid ${Colors.borderGray}`,
                        }}>
                        <div>
                            <span className="text text-bold">{t("recalls:instructions.title")}</span>
                        </div>
                        <div>
                            <CaretDropdown dropDown={vinInfoCollapsed} />
                        </div>
                    </div>

                    <Collapse isOpen={vinInfoCollapsed}>
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="col-12 col-lg-6">
                                <div className="flex flex-column px-5 lh-md">
                                    <div className="my-4 d-flex justify-content-center">
                                        <img src={artVinVehicle} alt="art-vin-vehicle" style={{ height: 170 }} />
                                    </div>

                                    <div className="mb-3">
                                        <Text i18nKey={"recalls:instructions.0.0"} />
                                        <strong>
                                            <Text i18nKey={"recalls:instructions.0.1"} />
                                        </strong>
                                        <Text i18nKey={"recalls:instructions.0.2"} />
                                    </div>
                                    <ol className="vin-list-style">
                                        <li>
                                            <Text i18nKey={"recalls:instructions.0.3"} />
                                        </li>
                                        <li>
                                            <Text i18nKey={"recalls:instructions.0.4"} />
                                        </li>
                                        <li>
                                            <Text i18nKey={"recalls:instructions.0.5"} />
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="flex flex-column px-5">
                                    <div className="my-4 d-flex justify-content-center">
                                        <img src={artVinLicensePlate} alt="art-vin-license-plate" style={{ height: 170 }} />
                                    </div>

                                    <div className="mb-3 text-center">
                                        <Text i18nKey={"recalls:instructions.1.0"} />
                                        <Text i18nKey={"recalls:instructions.1.1"} />
                                        <strong style={{ color: Colors.error }}>
                                            <Text i18nKey={"recalls:instructions.1.2"} />
                                        </strong>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Collapse>
                </Card>
            </div>
        </div>
    )
}

export default WhereVinNumberCard