import React from "react";
import './mdl.css'
import { useTranslation } from "react-i18next";

import iconHeader from '../../assets/images/mdl/Art_Instrucciones_RenovacionLicencia_04.svg'
import iconLicense from '../../assets/images/mdl/icon_mD-ER_Instrucciones_Licencia.svg'
import iconCita from '../../assets/images/mdl/icon_mD-ER_Instrucciones_Cita.svg'
import { useHistory, useLocation } from 'react-router-dom'

const MdlOnsite = () => {

    const { t } = useTranslation()
    const history = useHistory()

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const id = params.get("id")

    const buttonAction = () => {
        history.push(`/dl?path=mdl-extended-review&nav=appointment&id=${id}`)
    }

    return (
        <div>
            <div className="mdl-container">
                <div className="header">
                    <h1>{t("mdl:onsite.title")}</h1>
                    <img src={iconHeader} className="iconHeader" alt="" aria-hidden="true" />
                </div>

                <p>
                    {t("mdl:onsite.text.0")}
                </p>

                <p>
                    {t("mdl:onsite.text.1")}
                    <strong>{t("mdl:onsite.text.2")}</strong>
                    {t("mdl:onsite.text.3")}
                    <strong>{t("mdl:onsite.text.4")}</strong>
                    {t("mdl:onsite.text.5")}
                </p>

                <p>
                    <strong>{t("mdl:onsite.continue")}:</strong>
                </p>

                <div>
                    <div className="listItemHead">
                        <img src={iconLicense} className="icon" alt="" aria-hidden="true" />
                        <h3>
                            <strong>{t("mdl:onsite.license.title")}</strong>
                        </h3>
                    </div>
                    <div>
                        <p>
                            {t("mdl:onsite.license.text.0")}
                            <strong>{t("mdl:onsite.license.text.1")}</strong>
                        </p>
                    </div>
                </div>

                <div >
                    <div className="listItemHead">
                        <img src={iconCita} className="icon" alt="" aria-hidden="true" />
                        <h3 >
                            <strong>{t("mdl:onsite.appointment.title")}</strong>
                        </h3>
                    </div>
                    <div>
                        <p>
                            {t("mdl:onsite.appointment.text")}
                        </p>
                    </div>
                </div>

                <button className="btn btn-primary w-100" onClick={() => buttonAction()}>
                    {t("mdl:onsite.button")}
                </button>
            </div>
        </div>
    )
};

export default MdlOnsite;
