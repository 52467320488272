import React from "react";

const CaretDropdown = ({ dropDown }) => {
    return (
        <div className="caret-right ml-3"
            style={{
                transform: dropDown && "rotate(90deg)",
                transition: "all 0.4s ease",
            }}
        />
    )
}

export default CaretDropdown;