import React, {useEffect, useState} from 'react';
import PageTitle from "../../components/ui/PageTitle";
import ComponentTitle from "../../components/ui/ComponentTitle";
import Text from "../../components/ui/Text";
import Card from "../../components/ui/Card";
import {useTranslation} from "react-i18next";
import {Colors} from "../../Colors";
import {useHistory} from "react-router-dom";

import iconEmailInvalid from "../../assets/images/icon-email-invalid.svg";
import iconValid from "../../assets/images/icon-check-blue.svg";
import iconEnter from "../../assets/images/icon-enter.svg";
import iconEnterDisabled from "../../assets/images/icon_Enter_Disable.svg";
import iconEdit from "../../assets/images/icon_Edit.svg";

import {useDispatch, useSelector} from "react-redux";
import {savePhone} from "../../core/api-resources/profile";
import {getProfile} from "../../redux/profile/profile.combine";
import InputNotificationPopup from "../Login/InputNotificationPopup";


const SettingList = (props) => {
    const {t} = useTranslation()
    const {changeLang, lang, phoneError } = props

    return (
        <Card noPadding>
            <ul className="list-group">
                {
                    props.options?.map((e) => (
                        <div
                            className={`list-group-item d-flex justify-content-between align-items-center ${e.action && 'hoverable'}`}
                            style={{
                                borderRadius: 0,
                                paddingTop: 18,
                                paddingBottom: 18,
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                backgroundColor: e.phoneInput && phoneError ? Colors.errorBg : "white"
                            }}
                            onClick={() => e.action && e.action()}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "80%",
                                }}
                            >
                                {e.element && e.element}
                            </div>

                            {e.icon &&
                            <span style={{display: "flex"}}>
                                    <img src={e.icon} alt="" aria-hidden="true" style={{width: 24}}/>
                                </span>
                            }
                            {e.button && e.button}
                            {e.lang &&
                            <div style={{color: Colors.primary}}>
                                <a
                                    className="text"
                                    onClick={() => {
                                        changeLang("es-US");
                                    }}
                                    role={"button"}
                                    aria-label={t(
                                        "layout:header.arias.spanish"
                                    )}
                                    style={{
                                        textDecoration:
                                            lang === "es-US"
                                                ? "underline"
                                                : "none",
                                        fontWeight:
                                            lang === "es-US"
                                                ? "bold"
                                                : "",
                                        cursor: "pointer",
                                    }}
                                >
                                    Español
                                </a>
                                <span className="ml-1 mr-1">|</span>
                                <a
                                    className="text"
                                    aria-label={t(
                                        "layout:header.arias.english"
                                    )}
                                    role={"button"}
                                    onClick={() => {
                                        changeLang("en-US");
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        textDecoration:
                                            lang === "en-US"
                                                ? "underline"
                                                : "none",
                                        fontWeight:
                                            lang === "en-US"
                                                ? "bold"
                                                : "",
                                    }}
                                >
                                    English
                                </a>
                            </div>
                            }
                        </div>
                    ))
                }
            </ul>
        </Card>
    )
}


const Settings = (props) => {
    const [phoneInputValue, setPhoneInputValue] = useState("")
    const [phoneSaveError, togglePhoneSaveError] = useState(false)
    const [phoneInputError, setPhoneInputError] = useState(false)

    const [loading, setLoading] = useState(false)

    let profile = useSelector((state) => state.profile);

    const dispatch = useDispatch()
    const history = useHistory();
    const {t} = useTranslation()

    const {lang, changeLang} = props

    useEffect(() => {
        if (profile?.phone) setPhoneInputValue(profile?.phone)
    }, [profile])

    const savePhoneNumber = async (phone) => {
        setLoading(true)
        // AT&T PLACEHOLDER CARRIER
        await savePhone(phone, onPhoneSaveSuccess, onPhoneSaveError)
    }

    const onPhoneSaveSuccess = () => {
        dispatch(getProfile())
        setLoading(false)
    }

    const onPhoneSaveError = (error) => {
        togglePhoneSaveError(true)
        setTimeout(() => {
            togglePhoneSaveError(false)
            if (profile?.phone) setPhoneInputValue(profile?.phone)
        }, 5000)
        setLoading(false)
    }

    const editPhone = () => {
        document.getElementById("phoneInput").focus();
    }

    const handleChange = (e) => {
        setPhoneInputValue(e.target.value)

        if (!validPhoneNumber(e.target.value)) {
            setPhoneInputError(true)
        } else {
            setPhoneInputError(false)
        }
    }

    const validPhoneNumber = (phone) => {
        let regex = /^[0-9-]{10,15}$/;
        return regex.test(phone)
    }

    const renderPhoneIcon = () => {

        if (loading) return iconEnterDisabled

        if (profile?.validPhone && profile?.phone === phoneInputValue) {
            return iconEdit;
        }

        return  validPhoneNumber(phoneInputValue) ? iconEnter : iconEnterDisabled
    }

    const handlePhoneAction = () => {
        if (profile?.validPhone && profile?.phone === phoneInputValue) {
            editPhone()
        } else if (validPhoneNumber(phoneInputValue)) {
            savePhoneNumber(phoneInputValue)
        }
    }

    const ProfileOptions = [
        {
            element: profile?.email ?
                <Text className="text">{profile?.email}</Text> :
                <Text className="text" style={textElementStyle} i18nKey={"settings:email"}/>,
            icon: profile?.validEmail ? iconValid : iconEmailInvalid,
            action: () => history.push('/email/validation')
        },
        {
            name: "settings:phoneNo",
            element: <input
                id="phoneInput"
                value={phoneInputValue}
                onChange={handleChange}
                style={phoneInputStyle}
                className="form-control form-control-sm text"
                placeholder={t("settings:phoneNo")}
                type="string"
                disabled={loading}
                maxLength={15}
            />,
            icon: renderPhoneIcon(),
            action: handlePhoneAction,
            noPadding: true,
            phoneInput: true
        },
    ]

    const LanguageOptions = [
        {
            element: <Text className="text" style={textElementStyle} i18nKey={"settings:changeLang"}/>,
            lang: true
        }
    ]

    return (
        <>
            <div className="container mt-1 p-mob-only">
                <PageTitle
                    style={{fontWeight: "bold"}}
                    i18nKey={"settings:pageTitle"}
                />
            </div>

            <div className="container" style={{padding: 0}}>
                <div className="mt-4">
                    <ComponentTitle i18nKey={"settings:profile"}/>
                    {phoneSaveError && <InputNotificationPopup message={"settings:phoneSaveError"}/>}
                    <SettingList 
                        title={"settings:profile"} 
                        options={ProfileOptions}
                        phoneError={phoneInputError}
                    />
                </div>

                <div className="mt-4">
                    <ComponentTitle i18nKey={"settings:language"}/>
                    <SettingList title={"settings:language"} options={LanguageOptions} lang={lang}
                                 changeLang={changeLang}/>
                </div>

            </div>
        </>
    );
};

const textElementStyle = {
    display: "flex", alignItems: "center", textAlign: 'left', color: Colors.gray,
    cursor: 'pointer'
}

const phoneInputStyle = {
    backgroundColor: 'transparent',
    width: '100%',
    height: 18,
    border: 'none',
    padding: 0,
    color: Colors.gray,
    borderRadius: 0,
    cursor: 'pointer'
}

export default Settings;